import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { Grid, Typography, Tooltip, Card, CardContent, CardActions, IconButton, CircularProgress, ErrorHandling, makeStyles, useTheme, useMediaQuery, } from "../../components/mui";
import { Edit } from "@mui/icons-material";
import constants from "../../utils/constants";
import { ContentToPDF, getContent } from "../common/content";
import { ResponsiveChartContainer } from "@mui/x-charts/ResponsiveChartContainer";
import { BarPlot } from "@mui/x-charts/BarChart";
import { LinePlot, MarkPlot } from "@mui/x-charts/LineChart";
import { ScatterPlot } from "@mui/x-charts/ScatterChart";
import { ChartsXAxis } from "@mui/x-charts/ChartsXAxis";
import { ChartsYAxis } from "@mui/x-charts/ChartsYAxis";
import { ChartsGrid } from "@mui/x-charts/ChartsGrid";
import { ChartType } from "../../utils/enums";
import { SimplePieChart } from "../common/charts";
const REPORT = gql `
  query Report($id: ID!) {
    currentOrganization {
      id
      primaryTheme
    }
    report(id: $id) {
      id
      label
      chartType
      data {
        value
        label
      }
    }
  }
`;
const useStyles = makeStyles((theme) => ({
    card: {
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 8,
    },
    chart: {
        width: 350,
        height: 400,
        [theme.breakpoints.down("md")]: {
            width: 200,
            height: 250,
        },
        [theme.breakpoints.down("sm")]: {
            width: 200,
            height: 200,
        },
    },
}));
const Report = ({ id = null, onActionClick = () => null, manageActionPermitted = false, downloadActionPermitted = false, }) => {
    const classes = useStyles();
    const theme = useTheme();
    const mdMatches = useMediaQuery(theme.breakpoints.down("md"));
    const [getReport, { loading, error, data }] = useLazyQuery(REPORT);
    React.useEffect(() => {
        if (id) {
            getReport({
                variables: { id },
            });
        }
    }, [id]);
    if (loading)
        return (_jsx(Card, { children: _jsx(CardContent, { children: _jsx(Grid, { container: true, alignItems: "center", justifyContent: "center", children: _jsx(Grid, { item: true, sx: { margin: "20%" }, children: _jsx(CircularProgress, { size: 40, color: "primary" }) }) }) }) }));
    if (error)
        return _jsx(ErrorHandling, { error: error });
    return (_jsxs(Card, { className: classes.card, children: [_jsx(ContentToPDF, { disabled: !downloadActionPermitted, label: "Download", filename: `${data?.report?.label}.pdf`, children: _jsx(CardContent, { sx: {
                        "&:last-child": {
                            paddingLeft: 0,
                            paddingRight: 0,
                            paddingBottom: 0,
                        },
                    }, children: _jsxs(Grid, { container: true, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "h6", fontWeight: "bold", color: "primary", children: data?.report?.label }) }), _jsx(Grid, { item: true, xs: 12, className: classes.chart, children: data?.report?.chartType === ChartType.pie ? (_jsx(SimplePieChart, { data: data?.report?.data || [], height: mdMatches ? 250 : 400, width: mdMatches ? 250 : 400 })) : (_jsxs(ResponsiveChartContainer, { series: [
                                        {
                                            type: data?.report?.chartType,
                                            data: data?.report?.chartType === ChartType.scatter
                                                ? data?.report?.data?.map(({ label, value }, index) => ({
                                                    id: index,
                                                    x: label,
                                                    y: value,
                                                }))
                                                : data?.report?.data?.map(({ label, value }) => value),
                                            scaleType: data?.report?.chartType === ChartType.bar ? "band" : "point",
                                            markerSize: 8,
                                            showMark: true,
                                            color: data?.currentOrganization?.primaryTheme,
                                        },
                                    ], xAxis: [
                                        {
                                            data: data?.report?.data?.map(({ label, value }) => label),
                                            scaleType: data?.report?.chartType === ChartType.bar ? "band" : "point",
                                            categoryGapRatio: 0.5,
                                            barGapRatio: 0.5,
                                            id: "x-axis-id",
                                        },
                                    ], height: mdMatches ? 250 : 400, children: [_jsx(ChartsGrid, { vertical: true, horizontal: true }), _jsx(BarPlot, {}), _jsx(LinePlot, {}), _jsx(ScatterPlot, {}), _jsx(ChartsXAxis, { label: "", position: "bottom", axisId: "x-axis-id" }), _jsx(ChartsYAxis, {}), _jsx(MarkPlot, {})] })) })] }) }) }), manageActionPermitted ? (_jsx(CardActions, { disableSpacing: true, sx: {
                    alignSelf: "stretch",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    p: 0,
                }, children: _jsx(Tooltip, { title: getContent("manageReportMessage"), children: _jsx(IconButton, { size: "medium", onClick: () => onActionClick({
                            open: true,
                            id: data?.report?.id,
                            action: constants.OPERATION_TYPE.EDIT,
                        }), children: _jsx(Edit, { fontSize: "inherit" }) }) }) })) : null] }));
};
export default Report;
