import { jsx as _jsx } from "react/jsx-runtime";
import { Snackbar, Alert, AlertTitle, Slide } from "@mui/material";
const FormSnackBar = ({ snackBar = {}, setSnackBar = () => null }) => {
    const getAlertStyles = () => {
        if (snackBar?.type === "success") {
            return { backgroundColor: "#65C466", color: "white" };
        }
        return {};
    };
    return (_jsx(Snackbar, { sx: { width: "100%" }, autoHideDuration: 1000, anchorOrigin: { vertical: "top", horizontal: "center" }, open: snackBar?.open || false, onClose: () => setSnackBar({
            open: false,
            message: "",
            type: "",
        }), TransitionComponent: Slide, children: _jsx(Alert, { sx: {
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                ...getAlertStyles(),
            }, variant: "filled", severity: snackBar?.type, children: _jsx(AlertTitle, { sx: { m: 0, p: 0 }, children: snackBar?.message }) }) }));
};
export default FormSnackBar;
