import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useMutation } from "@apollo/client";
import { Grid, FormDialog, DialogContent, DialogContentText, Button, IconButton, FormControl, InputLabel, Select, MenuItem, useTheme, useMediaQuery, TextField, } from "../../../mui";
import { Cancel } from "@mui/icons-material";
import { ContentActions, ContentTypography } from "../../content";
import { subscriptionCancellationReason } from "../../../../utils/enums";
import { camelCaseToWords } from "../../../../utils/common";
const CANCEL_SUBSCRIPTION = gql `
  mutation CancelSubscription($subscriptionId: ID!, $data: subscriptionInputType!) {
    cancelSubscription(subscriptionId: $subscriptionId, data: $data) {
      id
    }
  }
`;
const CancelSubscriptionDialog = () => {
    const theme = useTheme();
    const lgMatches = useMediaQuery(theme.breakpoints.down("lg"));
    const [open, setOpen] = React.useState(false);
    const [feedback, setFeedback] = React.useState(null);
    const [comment, setComment] = React.useState("");
    const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION);
    return (_jsxs(React.Fragment, { children: [lgMatches ? (_jsx(IconButton, { onClick: () => setOpen(!open), size: "medium", color: "error", children: _jsx(Cancel, { fontSize: "inherit" }) })) : (_jsxs(Button, { size: "small", color: "error", variant: "outlined", onClick: () => setOpen(!open), children: [_jsx(ContentTypography, { variant: "button", color: "error", sx: { textTransform: "none" }, field: "cancelLabel" }), _jsx(Cancel, { fontSize: "inherit" })] })), _jsx(FormDialog, { open: open, setOpen: setOpen, title: _jsx(ContentTypography, { variant: "h6", color: "error", field: "cancelSubscriptionLabel" }), actions: _jsx(ContentActions, { onClose: () => setOpen(!open), onSave: () => {
                        cancelSubscription({
                            variables: {
                                subscriptionId: data?.id,
                                data: {
                                    cancellationDetails: {
                                        feedback,
                                        comment,
                                    },
                                },
                            },
                        });
                        setOpen(false);
                    } }), children: _jsx(DialogContent, { children: _jsxs(Grid, { container: true, spacing: 4, children: [_jsx(Grid, { item: true, children: _jsx(DialogContentText, { children: _jsx(ContentTypography, { field: "cancelSubscriptionTitle" }) }) }), _jsx(Grid, { item: true, children: _jsx(DialogContentText, { children: _jsx(ContentTypography, { field: "cancelSubscriptionDescription" }) }) }), _jsx(Grid, { item: true, xs: 6, children: _jsxs(FormControl, { style: { width: "100%" }, children: [_jsx(InputLabel, { children: _jsx(ContentTypography, { field: "cancellationQuestion" }) }), _jsx(Select, { fullWidth: true, value: feedback, label: "select reason", onChange: (e) => setFeedback(e?.target?.value), size: "small", children: Object.keys(subscriptionCancellationReason)?.map((type, index) => (_jsx(MenuItem, { value: subscriptionCancellationReason[type], children: camelCaseToWords(type) }, index))) })] }) }), _jsx(Grid, { item: true, xs: 9, children: _jsx(TextField, { fullWidth: true, label: "Comment", multiline: true, rows: 4, maxRows: 4, value: comment, onChange: (e) => setComment(e?.target?.value) }) }), _jsx(Grid, { item: true, children: _jsx(DialogContentText, { children: _jsx(ContentTypography, { field: "thankYouMessage" }) }) })] }) }) })] }));
};
export default CancelSubscriptionDialog;
