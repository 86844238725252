import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { makeStyles, Grid, useMediaQuery, useTheme } from "../../components/mui";
import { useHistory } from "react-router-dom";
import NavigationHeader from "../../components/Navigation/NavigationHeader";
import NavigationFooter from "../../components/Navigation/NavigationFooter";
import SideBarNavigation from "../../components/Navigation/SideBarNavigation";
import RouteConstants from "../../routes/RouteConstants";
const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        width: "100%",
    },
    section: {
        marginBottom: 60,
    },
}));
const AppLayout = ({ children }) => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("lg"));
    React.useEffect(() => {
        const userId = localStorage.getItem("userId") || null;
        const organizationId = localStorage.getItem("organizationId") || null;
        if (!userId || !organizationId) {
            return history.push(RouteConstants.Login.path);
        }
    }, []);
    return (_jsxs("div", { className: classes.root, children: [_jsx(NavigationHeader, {}), _jsxs(Grid, { container: true, justifyContent: "center", className: classes.section, children: [!matches && (_jsx(Grid, { item: true, xs: 2, children: _jsx(SideBarNavigation, {}) })), _jsx(Grid, { item: true, xs: matches ? 12 : 10, children: children })] }), _jsx(NavigationFooter, {})] }));
};
export default AppLayout;
