import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { FormSnackBar, Loader, ErrorHandling } from "../../mui";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { ManagePoolContentDialog } from "../../common/dialogs";
import { FormContent } from "../../common/forms";
import { FormFieldOrder } from "../../../utils/enums";
import constants from "../../../utils/constants";
import defaults from "../../../utils/defaults";
import InvoicePreview from "./InvoicePreview";
import { getContent } from "../../common/content";
const INVOICE_FRAGMENT = gql `
  fragment InvoiceFragment on invoice {
    id
    createdAt
    updatedAt
    name
    currency
    status
    description
    dueDate
    nextPaymentAttempt
    effectiveAt
    accountCountry
    statementDescriptor
    amountDue
    amountPaid
    amountRemaining
    amountShipping
    attemptCount
    startingBalance
    endingBalance
    subtotal
    subtotalExcludingTax
    tax
    totalExcludingTax
    postPaymentCreditNotesAmount
    prePaymentCreditNotesAmount
    attempted
    autoAdvance
    billingReason
    collectionMethod
    customerEmail
    customerPhone
    customerTaxExempt
    invoicePdf
    lastFinalizationError
    number
    receiptNumber
    livemode
    paid
    paidOutOfBand
    poolId
  }
`;
const INVOICES = gql `
  query InvoicesView($filters: queryFilters!) {
    __type(name: "invoice") {
      name
      fields {
        name
        type {
          name
          kind
          enumValues {
            name
          }
        }
      }
    }
    invoices(filters: $filters) {
      count
      page
      rowsPerPage
      order
      orderBy
      search
      view
      headers {
        label
        key
        component
        scope
        align
        numeric
        width
      }
      list {
        ...InvoiceFragment
      }
    }
  }
  ${INVOICE_FRAGMENT}
`;
const INVOICE = gql `
  query InvoiceQuery($id: ID!) {
    invoice(id: $id) {
      id
      ...InvoiceFragment
      client {
        id
      }
      accountOwner {
        id
      }
      deal {
        id
      }
      readOnly {
        label
        value
      }
      contents {
        id
        key
        value
        schemaId
        poolId
      }
    }
  }
  ${INVOICE_FRAGMENT}
`;
const ADD_INVOICE = gql `
  mutation AddInvoice($data: invoiceInput!) {
    addInvoice(data: $data) {
      ...InvoiceFragment
    }
  }
  ${INVOICE_FRAGMENT}
`;
const EDIT_INVOICE = gql `
  mutation EditInvoice($id: ID!, $data: invoiceInput!) {
    editInvoice(id: $id, data: $data) {
      ...InvoiceFragment
    }
  }
  ${INVOICE_FRAGMENT}
`;
const REMOVE_INVOICE = gql `
  mutation RemoveInvoice($id: ID!) {
    removeInvoice(id: $id) {
      success
    }
  }
`;
const EXPORT_INVOICES = gql `
  mutation ExportInvoices($filters: queryFilters!) {
    exportInvoices(filters: $filters) {
      id
    }
  }
`;
const IMPORT_INVOICES = gql `
  mutation ImportInvoices($data: usageInput!) {
    importInvoices(data: $data) {
      id
    }
  }
`;
const Invoices = ({ organizationId = null, organizationModelId = null, modelName = null, category = null, permissions = [], }) => {
    const notIncludedFields = [
        "id",
        "selected",
        "edited",
        "createdAt",
        "updatedAt",
        "poolId",
        "contents",
        "readOnly",
        "__typename",
    ];
    const [invoice, setInvoice] = React.useState(defaults.invoice);
    const [actionDialogState, setActionDialogState] = React.useState({
        open: false,
        id: null,
        action: null,
    });
    const { loading, error, data, refetch } = useQuery(INVOICES, {
        variables: {
            filters: {},
        },
    });
    const [snackBar, setSnackBar] = React.useState({
        open: false,
        message: "",
        type: "",
    });
    const [getInvoice, { data: invoiceData }] = useLazyQuery(INVOICE);
    const [addInvoice] = useMutation(ADD_INVOICE, {
        refetchQueries: ["InvoicesView"],
    });
    const [editInvoice] = useMutation(EDIT_INVOICE, {
        refetchQueries: ["InvoicesView"],
    });
    const [removeInvoice] = useMutation(REMOVE_INVOICE, {
        refetchQueries: ["InvoicesView"],
    });
    const [exportInvoices] = useMutation(EXPORT_INVOICES);
    const [importInvoices] = useMutation(IMPORT_INVOICES, {
        refetchQueries: ["InvoicesView"],
    });
    React.useEffect(() => {
        if (actionDialogState?.action === constants.OPERATION_TYPE.EDIT && actionDialogState?.id) {
            const id = actionDialogState?.id;
            getInvoice({
                variables: { id },
            });
        }
    }, [actionDialogState]);
    React.useEffect(() => {
        if (invoiceData?.invoice) {
            setInvoice({
                ...invoiceData?.invoice,
                client: invoiceData?.invoice?.client?.id || "",
                accountOwner: invoiceData?.invoice?.accountOwner?.id || "",
                deal: invoiceData?.invoice?.deal?.id || "",
            });
        }
    }, [invoiceData]);
    if (loading)
        return _jsx(Loader, {});
    if (error)
        return _jsx(ErrorHandling, { error: error });
    const onContentAdd = (pool) => {
        const contents = pool?.contents?.map((content) => ({
            id: content.id,
            poolId: content.poolId,
            schemaId: content.schemaId,
            key: content.key,
            value: content.value,
        })) || [];
        let data = {
            ...pool,
            organizationId,
            contents,
            clientId: pool?.client,
            accountOwnerId: pool?.accountOwner,
            dealId: pool?.deal,
            dueDate: new Date(pool?.dueDate),
            effectiveAt: new Date(pool?.effectiveAt),
            amountDue: parseInt(pool?.amountDue * 100),
            amountPaid: parseInt(pool?.amountPaid * 100),
            amountRemaining: parseInt(pool?.amountRemaining * 100),
            amountShipping: parseInt(pool?.amountShipping * 100),
            startingBalance: parseInt(pool?.startingBalance * 100),
            endingBalance: parseInt(pool?.endingBalance * 100),
            subtotal: parseInt(pool?.subtotal * 100),
            subtotalExcludingTax: parseInt(pool?.subtotalExcludingTax * 100),
            totalExcludingTax: parseInt(pool?.totalExcludingTax * 100),
            tax: parseInt(pool?.tax * 100),
            postPaymentCreditNotesAmount: parseInt(pool?.postPaymentCreditNotesAmount * 100),
            prePaymentCreditNotesAmount: parseInt(pool?.prePaymentCreditNotesAmount * 100),
            attemptCount: parseInt(pool?.attemptCount),
        };
        delete data?.client;
        delete data?.accountOwner;
        delete data?.deal;
        delete data?.readOnly;
        addInvoice({
            variables: {
                data,
            },
            onCompleted: ({ addInvoice }) => setSnackBar({
                open: true,
                message: `${addInvoice?.name} ${getContent("createSuccessMessage")}`,
                type: "success",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
        setActionDialogState({
            open: false,
            id: null,
            action: null,
        });
    };
    const onContentEdit = (pool) => {
        const contents = pool?.contents?.map((content) => ({
            id: content.id,
            poolId: content.poolId,
            schemaId: content.schemaId,
            key: content.key,
            value: content.value,
        })) || [];
        let data = {
            ...pool,
            organizationId,
            contents,
            clientId: pool?.client,
            accountOwnerId: pool?.accountOwner,
            dealId: pool?.deal,
            dueDate: new Date(pool?.dueDate),
            effectiveAt: new Date(pool?.effectiveAt),
            amountDue: parseInt(pool?.amountDue * 100),
            amountPaid: parseInt(pool?.amountPaid * 100),
            amountRemaining: parseInt(pool?.amountRemaining * 100),
            amountShipping: parseInt(pool?.amountShipping * 100),
            startingBalance: parseInt(pool?.startingBalance * 100),
            endingBalance: parseInt(pool?.endingBalance * 100),
            subtotal: parseInt(pool?.subtotal * 100),
            subtotalExcludingTax: parseInt(pool?.subtotalExcludingTax * 100),
            totalExcludingTax: parseInt(pool?.totalExcludingTax * 100),
            tax: parseInt(pool?.tax * 100),
            postPaymentCreditNotesAmount: parseInt(pool?.postPaymentCreditNotesAmount * 100),
            prePaymentCreditNotesAmount: parseInt(pool?.prePaymentCreditNotesAmount * 100),
            attemptCount: parseInt(pool?.attemptCount),
        };
        delete data?.id;
        delete data?.createdAt;
        delete data?.updatedAt;
        delete data?.selected;
        delete data?.editable;
        delete data?.poolId;
        delete data?.readOnly;
        delete data?.client;
        delete data?.deal;
        delete data?.accountOwner;
        delete data?.edited;
        delete data?.__typename;
        editInvoice({
            variables: {
                id: pool.id,
                data,
            },
            onCompleted: ({ editInvoice }) => setSnackBar({
                open: true,
                message: `${editInvoice?.name} ${getContent("updatedSuccessMessage")}`,
                type: "success",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
        setActionDialogState({
            open: false,
            id: null,
            action: null,
        });
    };
    const onContentDelete = () => {
        removeInvoice({
            variables: { id: invoice?.id },
            onCompleted: () => setSnackBar({
                open: true,
                message: `${invoice?.name} ${getContent("removedSuccessMessage")}`,
                type: "info",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
        setActionDialogState({
            open: false,
            id: null,
            action: null,
        });
    };
    const onDialogChange = ({ open = false, id = null, action = null }) => {
        setActionDialogState({
            open,
            id,
            action,
        });
    };
    const handleImport = ({ uploadAsset }) => {
        if (uploadAsset) {
            importInvoices({
                variables: {
                    data: { uploadAsset },
                },
                onCompleted: () => setSnackBar({
                    open: true,
                    message: `${getContent("importMutationMessage")}`,
                    type: "info",
                }),
                onError: () => setSnackBar({
                    open: true,
                    message: `${getContent("errorMessage")}`,
                    type: "error",
                }),
            });
        }
    };
    const handleExport = () => {
        exportInvoices({
            variables: {
                filters: {
                    order: data?.invoices?.order,
                    orderBy: data?.invoices?.orderBy,
                    search: data?.invoices?.search,
                },
            },
            onCompleted: () => setSnackBar({
                open: true,
                message: `${getContent("exportMutationMessage")}`,
                type: "info",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormSnackBar, { snackBar: snackBar, setSnackBar: setSnackBar }), _jsx(ManagePoolContentDialog, { displayPool: true, poolData: invoice || null, poolId: invoice?.poolId || null, defaultSchema: defaults.invoices, organizationModelId: organizationModelId, open: actionDialogState?.open || false, onDialogChange: onDialogChange, onSave: actionDialogState.action === constants.OPERATION_TYPE.ADD ? onContentAdd : onContentEdit, notIncludedFields: notIncludedFields, fields: data?.__type?.fields, operationType: actionDialogState?.action || constants.OPERATION_TYPE.ADD, onDelete: actionDialogState.action === constants.OPERATION_TYPE.EDIT ? onContentDelete : null, permissions: permissions, children: _jsx(InvoicePreview, { id: invoice?.id || null, permissions: permissions }) }), _jsx(FormContent, { pool: false, organizationModelId: organizationModelId || null, modelName: modelName, category: category, headers: data?.invoices?.headers || [], data: data?.invoices?.list || [], count: data?.invoices?.count || 0, page: data?.invoices?.page || 0, defaultView: data?.invoices?.view || null, order: data?.invoices?.order || FormFieldOrder.asc, orderBy: data?.invoices?.orderBy || constants.DEFAULT_SORT, search: data?.invoices?.search || "", defaultOrder: constants.DEFAULT_ORDER, rowsPerPage: data?.invoices?.rowsPerPage || 0, editMutation: onContentEdit, refetch: refetch, defaultSchema: defaults.invoices, notIncludedFields: notIncludedFields, fields: data?.__type?.fields, actionDialogState: actionDialogState?.open || false, setActionDialogState: onDialogChange, handleExport: handleExport, handleImport: handleImport, loading: loading, error: error, permissions: permissions })] }));
};
export default Invoices;
