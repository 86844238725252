import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useHistory } from "react-router-dom";
import { Grid, Typography, Avatar } from "@mui/material";
import { ContentTypography } from "../common/content";
import { capitalizeSentence } from "../../utils/common";
import RouteConstants from "../../routes/RouteConstants";
const Logo = ({ loggedIn = false, organization = null, defaultLogo = null }) => {
    const history = useHistory();
    return (_jsxs(Grid, { container: true, onClick: () => history.push(loggedIn ? RouteConstants.Dashboard.path : `${RouteConstants.Login.path}`), style: { cursor: "pointer" }, children: [organization?.logo ? (_jsx(Grid, { item: true, sx: { mr: 1 }, children: _jsx(Avatar, { alt: "avatar", src: organization?.logo?.url, sx: { width: 32, height: 32 } }) })) : (defaultLogo), _jsx(Grid, { item: true, children: loggedIn ? (_jsx(Typography, { variant: "h5", component: "h5", color: "primary", fontFamily: "Courier", fontWeight: "bold", children: capitalizeSentence(organization?.name) })) : (_jsx(ContentTypography, { field: "companyName", variant: "h5", component: "h5", color: "primary", fontFamily: "Courier", fontWeight: "bold" })) })] }));
};
export default Logo;
