const GermanContent = {
    companyName: "vermadev",
    companySlogan: "Nocode-Datenverwaltungslösung",
    submitLabel: "Einreichen",
    startHereLabel: "Fang hier an",
    loginHereLabel: "Hier anmelden",
    logoutLabel: "Ausloggen",
    generalLabel: "Allgemein",
    backLabel: "Zurück",
    profileLabel: "Profil",
    settingsLabel: "Einstellungen",
    integrationsLabel: "Integrationen",
    dashboardLabel: "Armaturenbrett",
    dashboardWelcomeLabel: "Willkommen!",
    reportsLabel: "Berichte",
    reportLabel: "Bericht",
    chartTypeLabel: "Diagramm Typ",
    listsLabel: "Liste",
    contentLabel: "Inhalt",
    relationsLabel: "Beziehungen",
    continueLabel: "Weitermachen",
    userNameLabel: "Nutzername",
    passwordLabel: "Passwort",
    nameLabel: "Name",
    industryLabel: "Industrie",
    websiteLabel: "Webseite",
    linkedinLabel: "Linkedin Url",
    facebookLabel: "Facebook Url",
    twitterLabel: "Twitter Url",
    angelLabel: "Angel Url",
    crunchbaseLabel: "Crunchbase Url",
    primaryThemeLabel: "Farbthema der Organisation",
    openSettingsLabel: "Einstellungen öffnen",
    addModelLabel: "Modell hinzufügen",
    modelLabel: "Modell",
    addPoolLabel: "Pool hinzufügen",
    dashboardNotificationsLabel: "Organisation Benachrichtigungen",
    notificationsLabel: "Benachrichtigungen",
    emailNotificationsLabel: "E-Mail Benachrichtigungen",
    emailNotificationsMessage: "E-Mail-Benachrichtigungen sind anpassbar, sodass Benutzer die Art der Benachrichtigungen auswählen können, die sie erhalten möchten, und ihre Präferenzen festlegen können",
    dailyReportsLabel: "Tagesberichte",
    dailyReportsMessage: "Erhalten Sie am Ende Ihres Tages einen detaillierten Bericht über die Aktivitäten der Organisation. Wir verwenden verschiedene Algorithmen und maschinelle Lerntechniken, um Ihnen die wichtigsten Details der Aktivität Ihres Teams zu vermitteln",
    dealsClosingAlert: "Benachrichtigungen zum Abschluss von Deals",
    dealsClosingMessage: "Seien Sie der Erste, der benachrichtigt wird, wenn Deals ausgeführt oder abgeschlossen werden",
    contentDeletionAlert: "Benachrichtigungen zum Löschen von Inhalten",
    contentDeletionMessage: "Die Genauigkeit Ihrer Daten ist wichtig. Lassen Sie sich benachrichtigen, wenn Assets oder Inhalte aus Ihrer Organisation entfernt werden",
    deviceNotificationsLabel: "Gerätebenachrichtigungen",
    securityUpdatesMessage: "Lassen Sie sich nur dann benachrichtigen, wenn es Sicherheitsbedenken in Bezug auf Ihr Unternehmen gibt",
    allUpdatesMessage: "Erhalten Sie alle wichtigen Updates und bleiben Sie mit jedem Live-Update über Ihr Unternehmen auf dem Laufenden",
    newPasswordLabel: "Neues Kennwort",
    bottomFooterLabel: "Sichere SSL-Registrierung",
    codeLabel: "Code",
    platformLanguageLabel: "Plattformsprache",
    platformCurrencyLabel: "Plattformwährung",
    organizationNameLabel: "Name der Organisation",
    confirmPasswordLabel: "Bestätige das Passwort",
    confirmationCodeLabel: "Bestätigungscode",
    resetPassLabel: "Passwort zurücksetzen",
    firstNameLabel: "Vorname",
    lastNameLabel: "Nachname",
    jobTitleLabel: "Berufsbezeichnung",
    emailLabel: "Email",
    defaultPassword: "Standard-Passwort",
    dobLabel: "Geburtsdatum",
    phoneNumberLabel: "Telefonnummer",
    contentCollageLabel: "Content-Management",
    relationsCollageLabel: "Beziehungsmanagement",
    forgotPassDescription: "Haben Sie Ihr Passwort vergessen?",
    codePassDescription: "Hast du schon ein Passwort?",
    resetPassDescription: "Stellen Sie es hier wieder her",
    resetCodeDescription: "Beenden Sie die Anmeldung hier",
    backToLoginDescription: "Zurück zur Anmeldung",
    resendCodeLabel: "Bestätigungscode erneut senden",
    resendCodeDescription: "Vielen Dank für Ihre Registrierung bei vermadev. Geben Sie Ihre E-Mail-Adresse ein, um erneut einen Bestätigungscode zu senden",
    verifyCodeDescription: "Senden Sie E-Mail-Verifizierung",
    confirmCodeDescription: "Ich habe meinen Code",
    signupSuccessDescription: "Vielen Dank für Ihre Registrierung bei vermadev. Wir haben Ihnen einen Bestätigungscode per E-Mail zugesandt. Bitte kopieren Sie den Code und fügen Sie ihn hier ein, um fortzufahren. Überprüfen Sie unbedingt Ihren Spam-Ordner",
    noDataDescription: "Hier werden keine Daten erstellt",
    addSectionLabel: "Abschnitt hinzufügen",
    invoicePreviewLabel: "Rechnungsvorschau",
    inventoriesLabel: "Vorräte",
    exploreLabel: "Erkunden",
    exploreMessage: "Mit dieser Funktion gelangen Sie zur gewünschten Organisation. Möchten Sie trotzdem fortfahren?",
    invoiceAmountLabel: "Gesamtmenge",
    reachOutMessageLabel: "Bei Fragen wenden Sie sich bitte an",
    payToLabel: "Zahlen Sie an",
    viewLabel: "Sicht",
    saveLabel: "Speichern",
    saveChangesLabel: "Änderungen speichern",
    yesLabel: "Ja",
    editLabel: "Bearbeiten",
    closeLabel: "Schließen",
    cancelLabel: "Stornieren",
    okayLabel: "Okay",
    errorLabel: "Fehler",
    downloadCSVLabel: "CSV Herunterladen",
    downloadPDFLabel: "PDF Herunterladen",
    newTeamMember: "Neues Teammitglied",
    teamMember: "Teammitglied",
    subscriptionLabel: "Abonnement",
    cancelSubscriptionLabel: "Abonnement beenden",
    areYouSureLabel: "Bist du sicher!",
    cancelSubscriptionTitle: "Sind Sie sicher, dass Sie mit der Kündigung Ihres Abonnements fortfahren möchten?",
    cancelSubscriptionDescription: "Wenn Sie mit dieser Aktion fortfahren, werden alle Konten aller Teammitglieder für diese Organisation gekündigt. Wir empfehlen Ihnen, Ihr Abonnement zu pausieren, falls Sie dies noch nicht getan haben, damit Sie weiterhin Zugriff auf alle Ihre Daten haben",
    cancellationQuestion: "Bitte wählen Sie einen Grund für Ihre Stornierung aus",
    thankYouMessage: "Vielen Dank, dass Sie sich für vermadev für Ihre Datenverwaltungsanforderungen entschieden haben. Der Datenschutz und der Schutz Ihrer Daten haben für uns höchste Priorität",
    pauseSubscription: "Abonnement pausieren",
    currentSubscriptionStatus: "Aktueller Abonnementstatus",
    pauseSubscriptionTitle: "Sind Sie sicher, dass Sie Ihr Abonnement pausieren möchten?",
    pauseSubscriptionDescription: "Wenn Sie fortfahren, werden wir Ihre Daten mit größtmöglicher Sicherheit verwahren. Sie können beliebige Datensätze problemlos exportieren. Nachdem diese Aktion ausgeführt wurde, werden alle Teammitglieder in Ihrer Organisation in die Rolle „Betrachter“ verschoben, was einen einfachen Lesezugriff auf die gesamte Plattform ermöglicht. Sie können Ihr Abonnement jederzeit hier fortsetzen und alle Ihre Dateneinstellungen bleiben erhalten",
    upgradeLabel: "Aktualisierung",
    upgradeSubscription: "Upgrade-Abonnement",
    upgradeSubscriptionTitle: "Bei Verma Consulting bieten wir stolz eine umfassende dynamische Entwicklungserfahrung an, die eng mit Ihren Geschäftsabläufen zusammenarbeitet",
    upgradeSubscriptionDescription: "Durch die Datensicherheit wird sichergestellt, dass sensible Informationen wie personenbezogene Daten (PII), Finanzdaten, geistiges Eigentum und Geschäftsgeheimnisse vertraulich bleiben und nur autorisierten Personen oder Organisationen zugänglich sind. Der unbefugte Zugriff auf solche Informationen könnte zu Identitätsdiebstahl, finanziellen Verlusten oder anderen Formen des Betrugs führen",
    currentStatusLabel: "Aktueller Status",
    periodEndsLabel: "Zeitraum endet",
    nextBillingLabel: "Nächstes Abrechnungsdatum",
    choosePlanLabel: "Wählen Sie einen Plan",
    selectProductLabel: "ausgewähltes Produkt",
    selectModelLabel: "Modell auswählen",
    selectRoleLabel: "Rolle auswählen",
    selectFrequencyLabel: "Frequenz auswählen",
    selectChartTypeLabel: "Diagrammtyp auswählen",
    roleLabel: "Rolle",
    frequencyLabel: "Frequenz",
    selectOperationLabel: "Vorgang auswählen",
    selectFieldLabel: "Feld auswählen",
    selectCalculationLabel: "Berechnung auswählen",
    calculationLabel: "Berechnung",
    calculationValueLabel: "Berechnungswert",
    expiresOnLabel: "Läuft aus am",
    paymentMethodLabel: "Bezahlverfahren",
    billingDetailsLabel: "Rechnungsdetails",
    manageFiltersLabel: "Filter verwalten",
    dataModelNameLabel: "Datenmodellname",
    filtersLabel: "Filtern",
    schemaLabel: "Schematische",
    addSchemaLabel: "Schematische hinzufügen",
    addOptionLabel: "Option hinzufügen",
    inRelationLabel: "Im Verhältnis",
    manageStatTitle: "Verwalten Sie die Statistiken für Ihr Modell",
    operationLabel: "Betrieb",
    typeLabel: "Typ",
    searchLabel: "Suchen",
    refreshLabel: "Aktualisierung",
    addLabel: "Hinzufügen",
    importLabel: "Importieren",
    exportLabel: "Export",
    paymentLabel: "Zahlung",
    commentsLabel: "Kommentare",
    addressLabel: "Adresse",
    assetsLabel: "Vermögenswerte",
    deleteLabel: "Löschen",
    permissionsLabel: "Berechtigungen",
    streetNumberLabel: "Hausnummer",
    streetNameLabel: "Straßenname",
    cityLabel: "Stadt",
    postalCodeLabel: "Postleitzahl",
    stateLabel: "Zustand",
    countryLabel: "Land",
    uploadFileLabel: "Datei hochladen",
    addStatLabel: "Statistik hinzufügen",
    addParentOrganizationLabel: "Übergeordnete Organisation hinzufügen",
    addSubOrganizationLabel: "Unterorganisation hinzufügen",
    addOrganizationLabel: "Organisation hinzufügen",
    parentOrganizationMessage: "Bitte geben Sie einen Namen ein, um fortzufahren",
    useDynamicMessage: "Verwenden Sie einen dynamischen Algorithmus",
    signUpPreMessage: "Sie haben kein Konto?",
    signUpPostMessage: "Jetzt beitreten",
    exportDescription: "Mit der Exportfunktion können Sie eine CSV-Datei generieren, die unter Berücksichtigung der aktuellen Filter für diesen Inhalt über einen herunterladbaren Link an Ihre E-Mail gesendet wird. Dies kann je nach Umfang der von Ihnen angeforderten Daten mehrere Minuten dauern",
    downloadCSVTitle: "Laden Sie eine Beispiel-CSV-Datei herunter",
    downloadCSVDescription: "Laden Sie eine Beispiel-CSV-Datei mit Daten für dieses Modell hoch und importieren Sie Einträge in großen Mengen in Ihre Datenpools. Bitte wählen Sie eine Datei aus, nachdem Sie auf „Senden“ geklickt haben",
    manageDataPoolProperties: "Datenpooleigenschaften verwalten",
    manageDataPoolLabel: "Datenpool verwalten",
    addToListLabel: "Zur Liste hinzufügen",
    selectListLabel: "Wählen Sie eine Liste aus, um Pools hinzuzufügen",
    schemaDialogTitle: "Es wurde noch kein zusätzliches Schema erstellt",
    schemaDialogSecondaryTitle: "Beginnen Sie hier ganz bequem mit dem Hinzufügen neuer Felder zu Ihrem Datenpool",
    organizationMessage: "Pflegen Sie Ihre Daten weiterhin in unserem vertrauenswürdigen Netzwerk und wählen Sie einen der folgenden Tarife. Wir empfehlen jedoch, sich an unser Team zu wenden, um gemeinsam eine spezifische Lösung für Ihre Datenverwaltungs- und Analyseanforderungen zu entwickeln",
    newOrganizationMessage: "Lassen Sie uns eine Organisation erstellen, damit Sie die Anmeldung abschließen können",
    deleteHandlingMessage: "Sobald dieser Inhalt aus dem Datenpool gelöscht wird, wird er aus jeder Instanz entfernt und kann nicht wiederhergestellt werden. Möchten Sie trotzdem weitermachen?",
    errorHandlingMessage: "Hoppla! Offenbar endete Ihr letzter Vorgang mit einem unten angezeigten Fehler. Wir empfehlen Ihnen, sich bitte erneut anzumelden. Dieser Vorfall wurde unserem Team gemeldet und die Fehlersuche wird in Kürze eingeleitet",
    signUpLabel: "Hier anmelden",
    upgradeMessage: "Genießen Sie Vermadev? Bitte erwägen Sie hier ein Upgrade Ihres Kontos",
    contentContainer: "Verwalten Sie hier die Content-Datenmodelle Ihrer Organisation",
    relationsContainer: "Verwalten Sie hier die Datenmodelle Ihrer Organisationsbeziehungen",
    listsContainer: "Verwalten Sie hier dynamische/statische Listen",
    reportsContainer: "Verwalten Sie hier Analyseberichte",
    settingsContainer: "Verwalten Sie hier Benutzer- und Organisationseinstellungen",
    integrationsContainer: "Verwalten Sie hier Integrationen mit externen Anwendungen",
    addModelMessage: "Fügen Sie hier ein neues Modell hinzu",
    addStatMessage: "Fügen Sie eine neue dynamische Statistik für dieses Datenmodell hinzu",
    searchMessage: "Zur Suche nach Namen einreichen",
    tableView: "Datenpools im Tabellenformat anzeigen",
    gridView: "Datenpools im Rasterformat anzeigen",
    kanbanView: "Datenpools im Kanban-Format anzeigen",
    modelMessage: "Verwalten Sie das Datenmodell hier",
    filtersMessage: "Verwalten Sie hier Datenmodellfilter",
    schemasMessage: "Verwalten Sie hier Datenmodellschemafelder",
    listsMessage: "Fügen Sie hier Inhalte zu einer vorhandenen Liste hinzu",
    importMessage: "Laden Sie hier ein Dokument in Massenimport-Datenpools hoch",
    exportMessage: "Erhalten Sie eine E-Mail mit einem Link zum Herunterladen einer CSV-Exportdatei dieses Datenmodells",
    refreshMessage: "Aktualisieren Sie den Inhalt hier in seinen natürlichen Zustand",
    addPoolMessage: "Fügen Sie hier neue Inhalte hinzu",
    manageContentMessage: "Inhalte hier verwalten",
    checkContentMessage: "Klicken Sie hier, um Aktualisierungen einzureichen",
    openContentMessage: "Klicken Sie hier, um zu diesem Inhalt zu navigieren",
    sortMessage: "Klicken Sie, um nach dieser Spalte zu sortieren",
    dragNDropMessage: "Ziehen Sie die Karte per Drag-and-Drop, um den Status zu ändern",
    addListMessage: "Fügen Sie hier Inhalte zu dieser Liste hinzu",
    navigateBackToLists: "Navigieren Sie zurück zu den Listen",
    manageReportMessage: "Bericht hier verwalten",
    addParentOrganization: "Fügen Sie Ihrer aktuellen Organisation eine übergeordnete Organisation hinzu",
    addSubOrganization: "Fügen Sie Ihrer aktuellen Organisation eine neue Unterorganisation hinzu",
    currentOrganizationMessage: "aktuelle Organisation, die für diese Sitzung erkundet werden soll",
    permissionMessage: "schalten Sie um, um den Zugriff auf diese Funktion im gesamten Team einzuschränken",
    pdfDownloadMessage: "Klicken Sie hier, um eine PDF-Datei dieses Inhalts herunterzuladen",
    csvDownloadMessage: "Klicken Sie hier, um eine CSV-Datei dieses Inhalts herunterzuladen",
    linkMessage: "Klicken Sie hier, um den Link zu besuchen",
    errorMessage: "Es ist ein Fehler aufgetreten!",
    createSuccessMessage: "erfolgreich erstellt!",
    updatedSuccessMessage: "erfolgreich geupdated!",
    removedSuccessMessage: "erfolgreich entfernt!",
    exportMutationMessage: "Exportanfrage erfolgreich übermittelt!",
    importMutationMessage: "Importanfrage erfolgreich gesendet!",
    labelText: "Etikett",
    financialsLabel: "Finanzen",
    financialsCollageLabel: "Finanzmanagement",
    financialsContainer: "Verwalten Sie hier die Finanzdatenmodelle Ihrer Organisation",
};
export default GermanContent;
