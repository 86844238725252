import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { Box, FormLabel, Typography, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, InputAdornment, ClickAwayListener, Link, Tooltip, useTheme, useMediaQuery, IconButton, } from "@mui/material";
import IOSSwitch from "./IOSSwitch";
import { Launch } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { ContentsSelect } from "../common/selects";
import { FormFieldType } from "../../utils/enums";
import constants from "../../utils/constants";
import { getContent } from "../common/content";
import { camelCaseToWords, largeNumberLabel, formatPhoneNumber, openInNewTab } from "../../utils/common";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import { MuiColorInput } from "mui-color-input";
import ReactPhoneInput from "react-phone-input-material-ui";
import CurrencyField from "./CurrencyField";
const useStyles = makeStyles((theme) => ({
    defaultMode: {
        paddingTop: 14,
        paddingBottom: 14,
        paddingLeft: 8,
        paddingRight: 8,
        cursor: "pointer",
        "&:hover": {
            borderRadius: 10,
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        },
    },
    formLabel: {
        cursor: "pointer",
    },
    formValue: {
        cursor: "pointer",
        wordBreak: "break-word",
    },
}));
const FormField = ({ fieldType = FormFieldType.TextField, relation = false, modelName = "", name = "", value = "", label = "", onChange, size = "small", disabled = false, defaultEditMode = false, enumValues = [], baseCurrency = null, poolCurrency = null, ...props }) => {
    const classes = useStyles();
    const theme = useTheme();
    const smMatches = useMediaQuery(theme.breakpoints.down("sm"));
    const [editMode, setEditMode] = React.useState(defaultEditMode || false);
    if (relation) {
        return (_jsx(ContentsSelect, { name: name, label: label, value: value, onChange: onChange, size: size, modelName: modelName, disabled: disabled, editMode: editMode, fieldType: fieldType, setEditMode: setEditMode }));
    }
    if (editMode) {
        switch (fieldType) {
            case name:
                return (_jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { children: label }), _jsx(Select, { name: name, size: size, value: value, label: label, onChange: onChange, open: editMode, onBlur: () => setEditMode(false), onClose: () => setEditMode(false), children: enumValues?.map((value, index) => (_jsx(MenuItem, { value: value, children: camelCaseToWords(value) }, index))) })] }));
            case FormFieldType.SingleSelect:
            case constants.GQL_FIELD_TYPE.Enum:
                return (_jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { children: label }), _jsx(Select, { name: name, size: size, value: value, label: label, onChange: onChange, open: editMode, onBlur: () => setEditMode(false), onClose: () => setEditMode(false), children: enumValues?.map((value, index) => (_jsx(MenuItem, { value: value, children: camelCaseToWords(value) }, index))) })] }));
            case constants.GQL_FIELD_TYPE.Float:
            case constants.GQL_FIELD_TYPE.Int:
            case FormFieldType.Number:
                return (_jsx(TextField, { fullWidth: true, type: "number", InputLabelProps: {
                        shrink: true,
                    }, variant: "standard", name: name, size: size, disabled: disabled, label: label, margin: "normal", value: value, onChange: onChange, open: editMode, onBlur: () => setEditMode(false), onKeyPress: (event) => {
                        if (event.key === "Enter") {
                            setEditMode(false);
                        }
                    }, inputRef: (input) => input && input.focus(), ...props }));
            case FormFieldType.Currency:
                return (_jsx(TextField, { fullWidth: true, name: name, size: size, disabled: disabled, label: label, margin: "normal", variant: "outlined", value: value, onChange: onChange, open: editMode, InputProps: {
                        startAdornment: (_jsx(InputAdornment, { position: "start", children: constants.CURRENCY_SYMBOLS?.[poolCurrency] || constants.CURRENCY_SYMBOLS.usd })),
                    }, onBlur: () => setEditMode(false), onKeyPress: (event) => {
                        if (event.key === "Enter") {
                            setEditMode(false);
                        }
                    }, inputRef: (input) => input && input.focus(), ...props }));
            case FormFieldType.Percentage:
                return (_jsx(TextField, { fullWidth: true, name: name, size: size, disabled: disabled, label: label, margin: "normal", variant: "outlined", value: value, onChange: onChange, open: editMode, InputProps: {
                        endAdornment: _jsx(InputAdornment, { position: "start", children: "%" }),
                    }, onBlur: () => setEditMode(false), onKeyPress: (event) => {
                        if (event.key === "Enter") {
                            setEditMode(false);
                        }
                    }, inputRef: (input) => input && input.focus(), ...props }));
            case FormFieldType.Link:
                return (_jsx(TextField, { fullWidth: true, name: name, size: size, disabled: disabled, label: label, margin: "normal", variant: "outlined", value: value, onChange: onChange, open: editMode, onBlur: () => setEditMode(false), onKeyPress: (event) => {
                        if (event.key === "Enter") {
                            setEditMode(false);
                        }
                    }, inputRef: (input) => input && input.focus(), ...props }));
            case constants.GQL_FIELD_TYPE.String:
            case FormFieldType.TextField:
                return (_jsx(TextField, { fullWidth: true, name: name, size: size, disabled: disabled, label: label, margin: "normal", variant: "outlined", value: value, onChange: onChange, open: editMode, onBlur: () => setEditMode(false), onKeyPress: (event) => {
                        if (event.key === "Enter") {
                            setEditMode(false);
                        }
                    }, inputRef: (input) => input && input.focus(), ...props }));
            case FormFieldType.Date:
                return (_jsx(LocalizationProvider, { dateAdapter: AdapterDayjs, children: smMatches ? (_jsx(MobileDatePicker, { format: "ll", slotProps: { textField: { size: "small", fullWidth: true } }, name: name, size: size, disabled: disabled, label: label, value: value ? dayjs(value) : null, onChange: (newValue) => onChange({
                            target: {
                                name,
                                value: newValue,
                            },
                        }), onClose: () => setEditMode(false) })) : (_jsx(DesktopDatePicker, { format: "ll", slotProps: { textField: { size: "small", fullWidth: true } }, name: name, size: size, disabled: disabled, label: label, value: value ? dayjs(value) : null, onChange: (newValue) => onChange({
                            target: {
                                name,
                                value: newValue,
                            },
                        }), onClose: () => setEditMode(false) })) }));
            case FormFieldType.Time:
                return (_jsx(TextField, { fullWidth: true, type: "time", name: name, size: size, disabled: disabled, label: label, margin: "normal", variant: "outlined", value: value, onChange: onChange, open: editMode, onBlur: () => setEditMode(false), onKeyPress: (event) => {
                        if (event.key === "Enter") {
                            setEditMode(false);
                        }
                    }, inputRef: (input) => input && input.focus(), ...props }));
            case FormFieldType.MultiSelect:
                return (_jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { children: label }), _jsx(Select, { multiple: true, name: name, size: size, value: value === "" ? [] : value?.split(", "), label: label, onChange: onChange, onBlur: () => setEditMode(false), onClose: () => setEditMode(false), children: enumValues?.map((value, index) => (_jsx(MenuItem, { value: value, children: camelCaseToWords(value) }, index))) })] }));
            case constants.GQL_FIELD_TYPE.Boolean:
            case FormFieldType.Switch:
                return (_jsx(FormControlLabel, { labelPlacement: "top", control: _jsx(IOSSwitch, { color: "primary", name: name, checked: Boolean(value), onChange: onChange, inputRef: (input) => input && input.focus() }), label: _jsx(Typography, { color: "secondary", children: label }), onBlur: () => setEditMode(false) }));
            case FormFieldType.Checkbox:
                return (_jsx(FormControlLabel, { labelPlacement: "top", control: _jsx(Checkbox, { color: "primary", name: name, checked: value?.toLowerCase?.() === "true", onChange: onChange, inputRef: (input) => input && input.focus() }), label: _jsx(Typography, { color: "secondary", children: label }), onBlur: () => setEditMode(false) }));
            case FormFieldType.Color:
                return (_jsx(ClickAwayListener, { onClickAway: () => setEditMode(false), children: _jsx(Box, { sx: { position: "relative" }, children: _jsx(MuiColorInput, { fullWidth: true, name: name, size: size, disabled: disabled, label: label, margin: "normal", variant: "outlined", open: editMode, onKeyPress: (event) => {
                                if (event.key === "Enter") {
                                    setEditMode(false);
                                }
                            }, ...props, format: "hex", value: value, onChange: (newValue) => {
                                onChange({
                                    target: {
                                        name,
                                        value: newValue,
                                    },
                                });
                            } }) }) }));
            case FormFieldType.PhoneNumber:
                return (_jsx(ReactPhoneInput, { defaultCountry: "usd", value: value, onChange: (newValue) => {
                        onChange({
                            target: {
                                name,
                                value: newValue,
                            },
                        });
                    }, inputProps: {
                        label,
                        size,
                        disabled,
                        autoFocus: true,
                    }, onBlur: () => setEditMode(false), component: React.forwardRef((props, ref) => (_jsx(TextField, { open: editMode, margin: "normal", variant: "outlined", inputRef: ref, onKeyPress: (event) => {
                            if (event.key === "Enter") {
                                setEditMode(false);
                            }
                        }, ...props }))) }));
            default:
                return null;
        }
    }
    function getValueLabel() {
        if (value === null) {
            return "–";
        }
        if ([FormFieldType.Checkbox, FormFieldType.Switch, constants.GQL_FIELD_TYPE.Boolean]?.includes(fieldType)) {
            return value ? "Yes" : "No";
        }
        if ([FormFieldType.Number, constants.GQL_FIELD_TYPE.Float]?.includes(fieldType)) {
            return largeNumberLabel(value);
        }
        if (fieldType === FormFieldType.Time) {
            if (!value || value === "")
                return "–";
            let hours = value?.split(":")?.[0];
            const minutes = value?.split(":")?.[1];
            const AmOrPm = hours >= 12 ? "PM" : "AM";
            hours = hours % 12 || 12;
            return hours + ":" + minutes + " " + AmOrPm;
        }
        if (fieldType === FormFieldType.Date) {
            return dayjs(value).format("MMM D, YYYY");
        }
        if (fieldType === FormFieldType.Currency) {
            return _jsx(CurrencyField, { value: value, poolCurrency: poolCurrency, baseCurrency: baseCurrency });
        }
        if (fieldType === FormFieldType.Percentage) {
            return `${largeNumberLabel(value)} %`;
        }
        if (fieldType === FormFieldType.PhoneNumber) {
            return formatPhoneNumber(value);
        }
        if (fieldType === FormFieldType.Link) {
            return (_jsxs(_Fragment, { children: [_jsx(Link, { children: value }), _jsx(Tooltip, { title: getContent("linkMessage"), children: _jsx(IconButton, { size: "small", onClick: () => openInNewTab(`https://${value}`), children: _jsx(Launch, { sx: { height: 16, width: 16 } }) }) })] }));
        }
        if (fieldType === FormFieldType.Color) {
            return (_jsx("div", { style: { margin: 8, display: "flex", width: 28, height: 28, backgroundColor: value, borderRadius: "50%" } }));
        }
        return value;
    }
    return (_jsxs("div", { onClick: () => {
            if (!disabled) {
                setEditMode(true);
            }
        }, className: classes.defaultMode, children: [_jsx(FormLabel, { className: classes.formLabel, children: label }), _jsx(Typography, { className: classes.formValue, children: getValueLabel() })] }));
};
export default FormField;
