import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { FormSnackBar, Loader, ErrorHandling } from "../mui";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { ManagePoolContentDialog } from "../common/dialogs";
import { FormContent } from "../common/forms";
import { FormFieldOrder } from "../../utils/enums";
import constants from "../../utils/constants";
import defaults from "../../utils/defaults";
import { getContent } from "../common/content";
const CAMPAIGN_FRAGMENT = gql `
  fragment CampaignFragment on campaign {
    id
    createdAt
    updatedAt
    name
    description
    status
    priority
    startDate
    endDate
    campaignType
    budgetedCost
    expectedRevenue
    actualCost
    numbersSent
    expectedResponse
    poolId
  }
`;
const CAMPAIGNS = gql `
  query CampaignsView($filters: queryFilters!) {
    __type(name: "campaign") {
      name
      fields {
        name
        type {
          name
          kind
          enumValues {
            name
          }
        }
      }
    }
    campaigns(filters: $filters) {
      count
      page
      rowsPerPage
      order
      orderBy
      search
      view
      headers {
        label
        key
        component
        scope
        align
        numeric
        width
      }
      list {
        ...CampaignFragment
      }
    }
  }
  ${CAMPAIGN_FRAGMENT}
`;
const CAMPAIGN = gql `
  query CampaignQuery($id: ID!) {
    campaign(id: $id) {
      id
      createdAt
      updatedAt
      name
      description
      status
      priority
      startDate
      endDate
      campaignType
      budgetedCost
      expectedRevenue
      actualCost
      numbersSent
      expectedResponse
      poolId
      accountOwner {
        id
      }
      leads {
        id
      }
      deals {
        id
      }
      readOnly {
        label
        value
      }
      contents {
        id
        key
        value
        schemaId
        poolId
      }
    }
  }
`;
const ADD_CAMPAIGN = gql `
  mutation AddCampaign($data: campaignInput!) {
    addCampaign(data: $data) {
      ...CampaignFragment
    }
  }
  ${CAMPAIGN_FRAGMENT}
`;
const EDIT_CAMPAIGN = gql `
  mutation EditCampaign($id: ID!, $data: campaignInput!) {
    editCampaign(id: $id, data: $data) {
      ...CampaignFragment
    }
  }
  ${CAMPAIGN_FRAGMENT}
`;
const REMOVE_CAMPAIGN = gql `
  mutation RemoveCampaign($id: ID!) {
    removeCampaign(id: $id) {
      success
    }
  }
`;
const EXPORT_CAMPAIGNS = gql `
  mutation ExportCampaigns($filters: queryFilters!) {
    exportCampaigns(filters: $filters) {
      id
    }
  }
`;
const IMPORT_CAMPAIGNS = gql `
  mutation ImportCampaigns($data: usageInput!) {
    importCampaigns(data: $data) {
      id
    }
  }
`;
const Campaigns = ({ organizationId = null, organizationModelId = null, modelName = null, category = null, permissions = [], }) => {
    const notIncludedFields = [
        "id",
        "selected",
        "edited",
        "createdAt",
        "updatedAt",
        "poolId",
        "contents",
        "readOnly",
        "__typename",
    ];
    const [campaign, setCampaign] = React.useState(defaults.campaign);
    const [actionDialogState, setActionDialogState] = React.useState({
        open: false,
        id: null,
        action: null,
    });
    const { loading, error, data, refetch } = useQuery(CAMPAIGNS, {
        variables: {
            filters: {},
        },
    });
    const [snackBar, setSnackBar] = React.useState({
        open: false,
        message: "",
        type: "",
    });
    const [getCampaign, { data: campaignData }] = useLazyQuery(CAMPAIGN);
    const [addCampaign] = useMutation(ADD_CAMPAIGN, {
        refetchQueries: ["CampaignsView"],
    });
    const [editCampaign] = useMutation(EDIT_CAMPAIGN, {
        refetchQueries: ["CampaignsView"],
    });
    const [removeCampaign] = useMutation(REMOVE_CAMPAIGN, {
        refetchQueries: ["CampaignsView"],
    });
    const [exportCampaigns] = useMutation(EXPORT_CAMPAIGNS);
    const [importCampaigns] = useMutation(IMPORT_CAMPAIGNS, {
        refetchQueries: ["CampaignsView"],
    });
    React.useEffect(() => {
        if (actionDialogState?.action === constants.OPERATION_TYPE.EDIT && actionDialogState?.id) {
            const id = actionDialogState?.id;
            getCampaign({
                variables: { id },
            });
        }
    }, [actionDialogState]);
    React.useEffect(() => {
        if (campaignData?.campaign) {
            setCampaign({
                ...campaignData?.campaign,
                accountOwner: campaignData?.campaign?.accountOwner?.id || "",
                leads: campaignData?.campaign?.leads?.map((lead) => lead?.id).join(", ") || "",
                deals: campaignData?.campaign?.deals?.map((deal) => deal?.id).join(", ") || "",
            });
        }
    }, [campaignData]);
    if (loading)
        return _jsx(Loader, {});
    if (error)
        return _jsx(ErrorHandling, { error: error });
    const onContentAdd = (pool) => {
        const contents = pool?.contents?.map((content) => ({
            id: content.id,
            poolId: content.poolId,
            schemaId: content.schemaId,
            key: content.key,
            value: content.value,
        })) || [];
        let data = {
            ...pool,
            organizationId,
            contents,
            accountOwnerId: pool?.accountOwner,
            leadIds: pool?.leads !== "" ? pool?.leads?.split(", ") : [],
            dealIds: pool?.deals !== "" ? pool?.deals?.split(", ") : [],
            budgetedCost: parseInt(pool?.budgetedCost * 100),
            expectedRevenue: parseInt(pool?.expectedRevenue * 100),
            actualCost: parseInt(pool?.actualCost * 100),
            startDate: new Date(pool?.startDate),
            endDate: new Date(pool?.endDate),
            numbersSent: parseFloat(pool?.numbersSent),
        };
        delete data?.accountOwner;
        delete data?.readOnly;
        delete data?.leads;
        delete data?.deals;
        addCampaign({
            variables: {
                data,
            },
            onCompleted: ({ addCampaign }) => setSnackBar({
                open: true,
                message: `${addCampaign?.name} ${getContent("createSuccessMessage")}`,
                type: "success",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
        setActionDialogState({
            open: false,
            id: null,
            action: null,
        });
    };
    const onContentEdit = (pool) => {
        const contents = pool?.contents?.map((content) => ({
            id: content.id,
            poolId: content.poolId,
            schemaId: content.schemaId,
            key: content.key,
            value: content.value,
        })) || [];
        let data = {
            ...pool,
            organizationId,
            contents,
            accountOwnerId: pool?.accountOwner,
            leadIds: pool?.leads !== "" ? pool?.leads?.split(", ") : [],
            dealIds: pool?.deals !== "" ? pool?.deals?.split(", ") : [],
            budgetedCost: parseInt(pool?.budgetedCost * 100),
            expectedRevenue: parseInt(pool?.expectedRevenue * 100),
            actualCost: parseInt(pool?.actualCost * 100),
            startDate: new Date(pool?.startDate),
            endDate: new Date(pool?.endDate),
            numbersSent: parseFloat(pool?.numbersSent),
        };
        delete data?.id;
        delete data?.createdAt;
        delete data?.updatedAt;
        delete data?.selected;
        delete data?.editable;
        delete data?.accountOwner;
        delete data?.poolId;
        delete data?.leads;
        delete data?.deals;
        delete data?.readOnly;
        delete data?.edited;
        delete data?.__typename;
        editCampaign({
            variables: {
                id: pool.id,
                data,
            },
            onCompleted: ({ editCampaign }) => setSnackBar({
                open: true,
                message: `${editCampaign?.name} ${getContent("updatedSuccessMessage")}`,
                type: "success",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
        setActionDialogState({
            open: false,
            id: null,
            action: null,
        });
    };
    const onContentDelete = () => {
        removeCampaign({
            variables: { id: campaign?.id },
            onCompleted: () => setSnackBar({
                open: true,
                message: `${campaign?.name} ${getContent("removedSuccessMessage")}`,
                type: "info",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
        setActionDialogState({
            open: false,
            id: null,
            action: null,
        });
    };
    const onDialogChange = ({ open = false, id = null, action = null }) => {
        setActionDialogState({
            open,
            id,
            action,
        });
    };
    const handleImport = ({ uploadAsset }) => {
        if (uploadAsset) {
            importCampaigns({
                variables: {
                    data: { uploadAsset },
                },
                onCompleted: () => setSnackBar({
                    open: true,
                    message: `${getContent("importMutationMessage")}`,
                    type: "info",
                }),
                onError: () => setSnackBar({
                    open: true,
                    message: `${getContent("errorMessage")}`,
                    type: "error",
                }),
            });
        }
    };
    const handleExport = () => {
        exportCampaigns({
            variables: {
                filters: {
                    order: data?.campaigns?.order,
                    orderBy: data?.campaigns?.orderBy,
                    search: data?.campaigns?.search,
                },
            },
            onCompleted: () => setSnackBar({
                open: true,
                message: `${getContent("exportMutationMessage")}`,
                type: "info",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormSnackBar, { snackBar: snackBar, setSnackBar: setSnackBar }), _jsx(ManagePoolContentDialog, { displayPool: true, poolData: campaign || null, poolId: campaign?.poolId || null, defaultSchema: defaults.campaigns, organizationModelId: organizationModelId, open: actionDialogState?.open || false, onDialogChange: onDialogChange, onSave: actionDialogState.action === constants.OPERATION_TYPE.ADD ? onContentAdd : onContentEdit, notIncludedFields: notIncludedFields, fields: data?.__type?.fields, operationType: actionDialogState?.action || constants.OPERATION_TYPE.ADD, onDelete: actionDialogState.action === constants.OPERATION_TYPE.EDIT ? onContentDelete : null, permissions: permissions }), _jsx(FormContent, { pool: false, organizationModelId: organizationModelId || null, modelName: modelName, category: category, headers: data?.campaigns?.headers || [], data: data?.campaigns?.list || [], count: data?.campaigns?.count || 0, page: data?.campaigns?.page || 0, defaultView: data?.campaigns?.view || null, order: data?.campaigns?.order || FormFieldOrder.asc, orderBy: data?.campaigns?.orderBy || constants.DEFAULT_SORT, search: data?.campaigns?.search || "", defaultOrder: constants.DEFAULT_ORDER, rowsPerPage: data?.campaigns?.rowsPerPage || 0, editMutation: onContentEdit, refetch: refetch, defaultSchema: defaults.campaigns, notIncludedFields: notIncludedFields, fields: data?.__type?.fields, actionDialogState: actionDialogState?.open || false, setActionDialogState: onDialogChange, handleExport: handleExport, handleImport: handleImport, loading: loading, error: error, permissions: permissions })] }));
};
export default Campaigns;
