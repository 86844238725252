import { jsx as _jsx } from "react/jsx-runtime";
import GoogleMapReact from "google-map-react";
const ContentMap = ({ lat = 37.7749, lng = -122.4194 }) => {
    const defaultProps = {
        center: {
            lat,
            lng,
        },
        zoom: 13,
    };
    return (_jsx("div", { style: { height: "300px", width: "100%", marginTop: 8, marginBottom: 8 }, children: _jsx(GoogleMapReact, { bootstrapURLKeys: { key: process.env.GOOGLE_MAPS_API || "" }, defaultCenter: defaultProps.center, defaultZoom: defaultProps.zoom }) }));
};
export default ContentMap;
