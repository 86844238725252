import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Grid, FormField, FormDialog } from "../../mui";
import { FormFieldType } from "../../../utils/enums";
import { ContentActions, ContentTypography, getContent } from "../content";
import { camelCaseToWords } from "../../../utils/common";
const FILTERS = gql `
  query FiltersDialog($organizationModelId: ID!) {
    filters(organizationModelId: $organizationModelId) {
      id
      key
      type
      value
      options
      organizationModelId
    }
  }
`;
const MANAGE_FILTERS = gql `
  mutation ManageFilters($data: [filterInput]) {
    manageFilters(data: $data) {
      id
    }
  }
`;
const ManagePoolFilters = ({ filters, setFitlers }) => (_jsx(Grid, { container: true, spacing: 2, children: filters?.map((filter, index) => (_jsx(Grid, { item: true, xs: 12, children: _jsx(FormField, { name: filter.key, label: camelCaseToWords(filter.key), fieldType: filter.type, enumValues: filter.options, value: filter.value, onChange: (event) => {
                const { target: { value }, } = event;
                const temp = filters.map((f) => {
                    if (f.key === filter.key) {
                        if (f.type === FormFieldType.MultiSelect) {
                            f.value = value.join(", ");
                        }
                        else {
                            f.value = value;
                        }
                    }
                    return f;
                });
                setFitlers(temp);
            }, size: "medium" }) }, index))) }));
const ManagePoolFiltersDialog = ({ open = true, onDialogChange = () => null, setSnackBar = () => null, organizationModelId = null, }) => {
    const [filters, setFitlers] = React.useState([]);
    const [getModelFilters, { data }] = useLazyQuery(FILTERS);
    const [manageFilters] = useMutation(MANAGE_FILTERS);
    React.useEffect(() => {
        if (open && organizationModelId) {
            getModelFilters({
                variables: {
                    organizationModelId,
                },
            });
        }
    }, [open]);
    React.useEffect(() => {
        setFitlers(data?.filters?.map((filter) => ({
            id: filter.id,
            organizationModelId: filter.organizationModelId,
            key: filter.key,
            value: filter.value,
            options: filter.options,
            type: filter.type,
        })));
    }, [data]);
    const onSaveFitlers = () => {
        manageFilters({
            variables: {
                data: filters,
            },
            onCompleted: () => setSnackBar({
                open: true,
                message: `Updated Model filters successfully!!`,
                type: "success",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
        onDialogChange();
    };
    return (_jsx(_Fragment, { children: _jsx(FormDialog, { open: open, setOpen: onDialogChange, title: _jsx(ContentTypography, { field: "manageFiltersLabel" }), actions: _jsx(ContentActions, { onClose: onDialogChange, onSave: onSaveFitlers }), children: _jsx(ManagePoolFilters, { filters: filters, setFitlers: setFitlers }) }) }));
};
export default ManagePoolFiltersDialog;
