import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useMutation } from "@apollo/client";
import { Grid, TextField, FormField, FormDialog, Typography } from "../../mui";
import { FormFieldType } from "../../../utils/enums";
import { ContentActions, getContent } from "../content";
const MANAGE_INTEGRATION = gql `
  mutation ManageIntegration($data: integrationInput!) {
    manageIntegration(data: $data) {
      id
    }
  }
`;
const IntegrationFields = ({ data, setData, error, setError }) => (_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 9, children: _jsx(TextField, { sx: { m: 1 }, fullWidth: true, error: error, helperText: error ? "This field is required. If not applicable please enter N/A" : "", name: "key", label: `Your ${data?.name} Access Key`, type: "password", size: "small", value: data.key, onChange: (event) => {
                    const { target: { name, value }, } = event;
                    setData({
                        ...data,
                        [name]: value,
                    });
                }, required: true }) }), _jsx(Grid, { item: true, xs: 9, children: _jsx(TextField, { sx: { m: 1 }, fullWidth: true, error: error, helperText: error
                    ? "This field is required. If not applicable please enter N/A"
                    : "All your secrets are store securely on cloud", name: "secret", label: `Your ${data?.name} Secret Key`, type: "password", size: "small", value: data.secret, onChange: (event) => {
                    const { target: { name, value }, } = event;
                    setData({
                        ...data,
                        [name]: value,
                    });
                }, required: true }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(FormField, { name: "active", label: "Active", fieldType: FormFieldType.Switch, value: data.active, onChange: (event) => {
                    const { target: { name, checked }, } = event;
                    setData({
                        ...data,
                        [name]: checked,
                    });
                }, size: "large" }) })] }));
const ManagePoolIntegrationDialog = ({ open = true, integration = null, onDialogChange = () => null, setSnackBar = () => null, }) => {
    const [data, setData] = React.useState(integration);
    const [error, setError] = React.useState(false);
    const [manageIntegration] = useMutation(MANAGE_INTEGRATION, {
        refetchQueries: ["IntegrationsView"],
    });
    const onSaveFitlers = () => {
        if (data?.key === "" || data?.secret === "") {
            setError(true);
        }
        else {
            delete data.new;
            manageIntegration({
                variables: {
                    data,
                },
                onCompleted: () => setSnackBar({
                    open: true,
                    message: `Updated Organization Integration successfully!!`,
                    type: "success",
                }),
                onError: () => setSnackBar({
                    open: true,
                    message: `${getContent("errorMessage")}`,
                    type: "error",
                }),
            });
            onDialogChange();
        }
    };
    return (_jsx(_Fragment, { children: _jsxs(FormDialog, { open: open, setOpen: onDialogChange, title: data?.name, actions: _jsx(ContentActions, { onClose: onDialogChange, onSave: onSaveFitlers }), children: [_jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { children: data?.title }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { color: "secondary", children: data?.description }) })] }), _jsx(Grid, { item: true, xs: 12, children: _jsx(IntegrationFields, { data: data, setData: setData, error: error, setError: setError }) })] }) }));
};
export default ManagePoolIntegrationDialog;
