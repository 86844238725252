import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { makeStyles, Grid, Typography, Tooltip, Tabs, Tab, TabPanel, Divider, useTheme, useMediaQuery, ErrorHandling, } from "../components/mui";
import { Add } from "@mui/icons-material";
import { CategoryType, ModelType, UserPermission, FeatureType, OrganizationPermission, OrganizationFeatures, } from "../utils/enums";
import { ContentTypography, getContent } from "../components/common/content";
import { Clients, Invoices, Inventories, Tasks } from "../components/CMS";
import Pools from "../components/Pools/Pools";
import ManageModel from "../components/Pools/ManageModel";
import AppLayout from "./common/AppLayout";
import constants from "../utils/constants";
import { ORGANIZATION_MODELS } from "./Queries";
import ColorModeContext from "../routes/ColorModeContext";
import RouteConstants from "../routes/RouteConstants";
import { camelCaseToWords } from "../utils/common";
const useStyles = makeStyles((theme) => ({
    section: {
        padding: 16,
        [theme.breakpoints.down("md")]: {
            padding: 12,
        },
        [theme.breakpoints.down("sm")]: {
            padding: 8,
        },
    },
    tabs: {
        width: "100%",
    },
}));
const tabsSequence = [ModelType.invoices, ModelType.inventories, ModelType.tasks, ModelType.clients];
const contentView = (organizationModel, permissions) => {
    switch (organizationModel?.model?.name) {
        case ModelType.clients:
            return (_jsx(Clients, { organizationId: organizationModel?.organizationId || null, organizationModelId: organizationModel?.id || null, modelName: organizationModel?.model?.name || null, category: CategoryType.content, permissions: permissions || [] }));
        case ModelType.inventories:
            return (_jsx(Inventories, { organizationId: organizationModel?.organizationId || null, organizationModelId: organizationModel?.id || null, modelName: organizationModel?.model?.name || null, category: CategoryType.content, permissions: permissions || [] }));
        case ModelType.tasks:
            return (_jsx(Tasks, { organizationId: organizationModel?.organizationId || null, organizationModelId: organizationModel?.id || null, modelName: organizationModel?.model?.name || null, category: CategoryType.content, permissions: permissions || [] }));
        case ModelType.invoices:
            return (_jsx(Invoices, { organizationId: organizationModel?.organizationId || null, organizationModelId: organizationModel?.id || null, modelName: organizationModel?.model?.name || null, category: CategoryType.content, permissions: permissions || [] }));
        default:
            return (_jsx(Pools, { organizationId: organizationModel?.organizationId || null, organizationModelId: organizationModel?.id || null, modelName: organizationModel?.model?.name || null, category: CategoryType.content, permissions: permissions || [] }));
    }
};
const ContentContainer = (props) => {
    const organizationId = localStorage.getItem("organizationId") || null;
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const mdMatches = useMediaQuery(theme.breakpoints.down("md"));
    const colorMode = React.useContext(ColorModeContext);
    const [tabValue, setTabValue] = React.useState(ModelType.clients);
    const [poolsPermitted, setPoolsPermitted] = React.useState(false);
    const [manageActionPermitted, setManageActionPermitted] = React.useState(false);
    const [permittedOrganizationModels, setPermittedOrganizationModels] = React.useState([]);
    React.useEffect(() => {
        const userId = localStorage.getItem("userId") || null;
        const organizationId = localStorage.getItem("organizationId") || null;
        if (!userId || !organizationId) {
            return history.push(RouteConstants.Login.path);
        }
    }, []);
    const { loading, error, data } = useQuery(ORGANIZATION_MODELS, {
        variables: {
            organizationId,
            modelCategory: CategoryType.content,
        },
    });
    React.useEffect(() => {
        const search = history.location.search;
        const params = new URLSearchParams(search);
        const viewParam = params.get("view");
        if (data) {
            const calManageActionPermitted = data?.currentUser?.permissions?.find((permission) => permission?.feature === FeatureType.DataPoolsManage)
                ?.permission === UserPermission.Manage;
            const organizationPermittedFeatures = data?.organization?.permissions?.map((permission) => permission?.permission === OrganizationPermission.Visible && permission?.feature);
            let calPermittedOrganizationModels = data?.organizationModels?.filter((organizationModel) => {
                if (Object.keys(ModelType).includes(organizationModel?.model?.name)) {
                    if (organizationPermittedFeatures.includes(organizationModel?.model?.name)) {
                        return organizationModel;
                    }
                }
                else {
                    return organizationModel;
                }
            });
            calPermittedOrganizationModels = calPermittedOrganizationModels.sort((a, b) => tabsSequence.indexOf(b?.model?.name) - tabsSequence.indexOf(a?.model?.name));
            const calPoolsPermitted = organizationPermittedFeatures.includes(OrganizationFeatures.dataPools);
            setPermittedOrganizationModels(calPermittedOrganizationModels || []);
            setManageActionPermitted(calManageActionPermitted || false);
            setPoolsPermitted(calPoolsPermitted || false);
            colorMode.defaultMode({
                mode: data?.currentUser?.platformSettings?.mode,
            });
            colorMode.togglePrimaryTheme({
                color: data?.organization?.primaryTheme || constants.DEFAULT_PRIMARY_THEME,
            });
            if (viewParam && calPermittedOrganizationModels?.find((orgModel) => orgModel?.model?.name === viewParam)) {
                history.replace({
                    search: `?view=${viewParam}`,
                });
                setTabValue(viewParam);
            }
            else {
                const view = calPermittedOrganizationModels?.[0]?.model?.name || ModelType.clients;
                history.replace({
                    search: `?view=${view}`,
                });
                setTabValue(view);
            }
        }
    }, [data, history]);
    if (loading)
        return null;
    if (error)
        return _jsx(ErrorHandling, { error: error });
    return (_jsx(AppLayout, { children: _jsxs(Grid, { container: true, spacing: 2, alignItems: "flex-start", justifyContent: "center", className: classes.section, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(ContentTypography, { align: "right", variant: "h5", color: "primary", m: 1, fontWeight: "bold", field: "contentCollageLabel" }) }), _jsx(Grid, { item: true, xs: 12, sm: 12, children: _jsx(Divider, { flexItem: true }) }), _jsxs(Grid, { item: true, xs: 12, className: classes.tabs, children: [_jsxs(Tabs, { value: tabValue, onChange: (event, newValue) => {
                                history.replace({
                                    search: `?view=${newValue}`,
                                });
                                setTabValue(newValue);
                            }, textColor: "primary", indicatorColor: "primary", variant: "scrollable", scrollButtons: false, children: [permittedOrganizationModels?.map((organizationModel, index) => (_jsx(Tab, { value: organizationModel?.model?.name, label: _jsx(Grid, { container: true, direciton: "row", alignItems: "center", justifyContent: "center", children: _jsxs(Typography, { variant: "subtitle2", fontWeight: "bold", sx: { textTransform: "none" }, children: ["\u00A0", camelCaseToWords(organizationModel?.model?.name)] }) }) }, index))), manageActionPermitted && poolsPermitted ? (_jsx(Tab, { label: mdMatches ? (_jsx(Add, { fontSize: "large", color: "primary" })) : (_jsx(Tooltip, { title: getContent("addModelMessage"), children: _jsxs(Grid, { container: true, children: [_jsx(Grid, { item: true, children: _jsx(ContentTypography, { variant: "subtitle2", fontWeight: "bold", sx: { textTransform: "none" }, field: "addModelLabel" }) }), _jsx(Grid, { item: true, children: _jsx(Add, { fontSize: "medium", color: "primary" }) })] }) })), value: constants.OPERATION_TYPE.ADD })) : null] }), permittedOrganizationModels?.map((organizationModel, index) => (_jsx(TabPanel, { value: tabValue, index: organizationModel?.model?.name || null, children: contentView(organizationModel, data?.currentUser?.permissions) }, index))), _jsx(TabPanel, { value: tabValue, index: constants.OPERATION_TYPE.ADD, children: _jsx(ManageModel, { category: CategoryType.content }) })] })] }) }));
};
export default ContentContainer;
