const FrenchContent = {
    companyName: "vermadev",
    companySlogan: "solution de gestion de données nocode",
    submitLabel: "Soumettre",
    startHereLabel: "Commencer ici",
    loginHereLabel: "Connectez-vous ici",
    logoutLabel: "Se déconnecter",
    generalLabel: "Général",
    backLabel: "Dos",
    profileLabel: "Profil",
    settingsLabel: "Paramètres",
    integrationsLabel: "Intégrations",
    dashboardLabel: "Tableau de bord",
    dashboardWelcomeLabel: "Bienvenue!",
    reportsLabel: "Rapports",
    reportLabel: "Rapport",
    chartTypeLabel: "Type de graphique",
    listsLabel: "Listes",
    contentLabel: "Contenu",
    relationsLabel: "Relations",
    continueLabel: "Continuer",
    userNameLabel: "Nom d'utilisateur",
    passwordLabel: "Mot de passe",
    nameLabel: "Nom",
    industryLabel: "Industrie",
    websiteLabel: "Site web",
    linkedinLabel: "Linkedin Url",
    facebookLabel: "Facebook Url",
    twitterLabel: "Twitter Url",
    angelLabel: "Angel Url",
    crunchbaseLabel: "Crunchbase Url",
    primaryThemeLabel: "Thème principal",
    openSettingsLabel: "Ouvrir les paramètres",
    addModelLabel: "Ajouter un modèle",
    modelLabel: "Modèle",
    addPoolLabel: "Ajouter un pool",
    dashboardNotificationsLabel: "Notifications de l'organisation",
    notificationsLabel: "Notifications",
    emailNotificationsLabel: "Notifications par email",
    emailNotificationsMessage: "Les notifications par e-mail sont personnalisables, permettant aux utilisateurs de choisir les types de notifications qu'ils souhaitent recevoir et de définir leurs préférences",
    dailyReportsLabel: "Rapports quotidiens",
    dailyReportsMessage: "Obtenez un rapport détaillé de l'activité de l'organisation à la fin de votre journée. Nous utilisons divers algorithmes et techniques d'apprentissage automatique pour vous apporter les détails les plus importants de l'activité de votre équipe.",
    dealsClosingAlert: "Alertes de clôture des transactions",
    dealsClosingMessage: "Soyez le premier à être averti lorsque les transactions sont exécutées ou clôturées",
    contentDeletionAlert: "Alertes de suppression de contenu",
    contentDeletionMessage: "Il est important de garder vos données précises. Soyez averti chaque fois que des actifs ou du contenu sont supprimés de votre organisation.",
    deviceNotificationsLabel: "Notifications de l'appareil",
    securityUpdatesMessage: "Soyez averti uniquement lorsqu'il s'agit de problèmes de sécurité liés à votre organisation",
    allUpdatesMessage: "Recevez toutes les mises à jour importantes et restez en contact avec chaque mise à jour en direct sur votre organisation",
    newPasswordLabel: "nouveau mot de passe",
    bottomFooterLabel: "Inscription sécurisée SSL",
    codeLabel: "Code",
    platformLanguageLabel: "Langue de la plateforme",
    platformCurrencyLabel: "Devise de la plateforme",
    organizationNameLabel: "nom de l'organisation",
    confirmPasswordLabel: "Confirmez le mot de passe",
    confirmationCodeLabel: "Code de confirmation",
    resetPassLabel: "réinitialiser le mot de passe",
    firstNameLabel: "Prénom",
    lastNameLabel: "Nom de famille",
    jobTitleLabel: "Titre d'emploi",
    emailLabel: "E-mail",
    defaultPassword: "Mot de passe par défaut",
    dobLabel: "Date de naissance",
    phoneNumberLabel: "Numéro de téléphone",
    contentCollageLabel: "Gestion de contenu",
    relationsCollageLabel: "Gestion de la relation",
    forgotPassDescription: "Mot de passe oublié?",
    codePassDescription: "Tu as déjà un code?",
    resetPassDescription: "Récupérez-le ici",
    resetCodeDescription: "Terminer Inscrivez-vous ici",
    backToLoginDescription: "Retour connexion",
    resendCodeLabel: "Renvoyer le code de confirmation",
    resendCodeDescription: "Merci de vous être inscrit sur vermadev, saisissez votre email pour renvoyer un code de confirmation",
    verifyCodeDescription: "Envoyer email de vérification",
    confirmCodeDescription: "j'ai mon code",
    signupSuccessDescription: "Merci de vous être inscrit sur vermadev. Nous avons envoyé un code de confirmation à votre adresse e-mail, veuillez copier et coller le code ici pour continuer. Assurez-vous de vérifier votre dossier spam",
    noDataDescription: "Aucune donnée créée ici",
    addSectionLabel: "Ajouter une section",
    invoicePreviewLabel: "Aperçu de la facture",
    inventoriesLabel: "Inventaires",
    exploreLabel: "Explorer",
    exploreMessage: "En utilisant cette fonctionnalité, vous accéderez à l’organisation souhaitée. Souhaitez-vous quand même continuer ?",
    invoiceAmountLabel: "Montant total",
    reachOutMessageLabel: "Pour toute question, veuillez contacter",
    payToLabel: "Payer pour",
    viewLabel: "Voir",
    saveLabel: "Sauvegarder",
    saveChangesLabel: "Sauvegarder les modifications",
    yesLabel: "Oui",
    editLabel: "Modifier",
    closeLabel: "Fermer",
    cancelLabel: "Annuler",
    okayLabel: "D'accord",
    errorLabel: "Erreur",
    downloadCSVLabel: "Télécharger CSV",
    downloadPDFLabel: "Télécharger le PDF",
    newTeamMember: "Nouveau membre de l'équipe",
    teamMember: "Membre de l'équipe",
    subscriptionLabel: "Abonnement",
    cancelSubscriptionLabel: "Annuler l'abonnement",
    areYouSureLabel: "Es-tu sûr!",
    cancelSubscriptionTitle: "Etes-vous sûr de vouloir procéder à l'annulation de votre abonnement ?",
    cancelSubscriptionDescription: "Poursuivre cette action mettra fin au compte de tous les membres de l'équipe pour cette organisation. Nous vous conseillons, si ce n'est pas déjà fait, de suspendre votre abonnement afin que vous puissiez toujours avoir accès à toutes vos données.",
    cancellationQuestion: "Veuillez sélectionner un motif pour votre annulation",
    thankYouMessage: "Merci d'avoir choisi vermadev pour vos besoins de gestion de données, la confidentialité et la protection de vos données sont notre priorité absolue.",
    pauseSubscription: "Suspendre l'abonnement",
    currentSubscriptionStatus: "Statut actuel de l'abonnement",
    pauseSubscriptionTitle: "Êtes-vous sûr de vouloir suspendre votre abonnement ?",
    pauseSubscriptionDescription: "Si vous continuez, nous garderons vos données en toute sécurité. Vous pouvez facilement exporter n’importe quel ensemble de données. Une fois cette action effectuée, tous les membres de l'équipe de votre organisation seront déplacés vers un rôle de spectateur, permettant un accès facile en lecture seule sur la plateforme. Vous pouvez choisir de reprendre votre abonnement ici à n'importe quel moment et tous vos paramètres de données seront conservés.",
    upgradeLabel: "Mise à niveau",
    upgradeSubscription: "Abonnement de mise à niveau",
    upgradeSubscriptionTitle: "Chez Verma Consulting, nous sommes fiers d'offrir une expérience de développement dynamique complète qui travaille en étroite collaboration avec vos opérations commerciales.",
    upgradeSubscriptionDescription: "La sécurité des données garantit que les informations sensibles, telles que les informations personnelles identifiables (PII), les données financières, la propriété intellectuelle et les secrets commerciaux, restent confidentielles et ne sont accessibles qu'aux personnes ou entités autorisées. Un accès non autorisé à ces informations pourrait entraîner un vol d'identité, une perte financière ou d'autres formes de fraude.",
    currentStatusLabel: "Statut actuel",
    periodEndsLabel: "Fin de la période",
    nextBillingLabel: "Prochaine date de facturation",
    choosePlanLabel: "Choisir le forfait",
    selectProductLabel: "sélectionner un produit",
    selectModelLabel: "sélectionner le modèle",
    selectRoleLabel: "sélectionner un rôle",
    selectFrequencyLabel: "sélectionner la fréquence",
    selectChartTypeLabel: "sélectionner le type de graphique",
    roleLabel: "Rôle",
    frequencyLabel: "Fréquence",
    selectOperationLabel: "sélectionner l'opération",
    selectFieldLabel: "sélectionner un champ",
    selectCalculationLabel: "sélectionner le calcul",
    calculationLabel: "Calcul",
    calculationValueLabel: "Valeur de calcul",
    expiresOnLabel: "Expire le",
    paymentMethodLabel: "Mode de paiement",
    billingDetailsLabel: "Détails de la facturation",
    manageFiltersLabel: "Gérer les filtres",
    dataModelNameLabel: "Nom du modèle de données",
    filtersLabel: "Filtres",
    schemaLabel: "Schéma",
    addSchemaLabel: "Ajouter un schéma",
    addOptionLabel: "Ajouter une option",
    inRelationLabel: "En relation",
    manageStatTitle: "Gérer les statistiques de votre modèle",
    operationLabel: "Opération",
    typeLabel: "Taper",
    searchLabel: "Recherche",
    refreshLabel: "Rafraîchir",
    addLabel: "Ajouter",
    importLabel: "Importer",
    exportLabel: "Exporter",
    paymentLabel: "Paiement",
    commentsLabel: "Commentaires",
    addressLabel: "Adresse",
    assetsLabel: "Actifs",
    deleteLabel: "Supprimer",
    permissionsLabel: "Autorisations",
    streetNumberLabel: "Numéro de rue",
    streetNameLabel: "Nom de rue",
    cityLabel: "Ville",
    postalCodeLabel: "Code Postal",
    stateLabel: "État",
    countryLabel: "Pays",
    uploadFileLabel: "Téléverser un fichier",
    addStatLabel: "Ajouter une statistique",
    addParentOrganizationLabel: "Ajouter une organisation parente",
    addSubOrganizationLabel: "Ajouter une sous-organisation",
    addOrganizationLabel: "Ajouter une organisation",
    parentOrganizationMessage: "Veuillez entrer un nom pour continuer",
    useDynamicMessage: "Utiliser un algorithme dynamique",
    signUpPreMessage: "Vous n'avez pas de compte ?",
    signUpPostMessage: "Adhérer maintenant",
    exportDescription: "La fonction d'exportation vous permet de générer un fichier csv, envoyé via un lien téléchargeable vers votre courrier électronique étant donné les filtres actuels pour ce contenu. Cela peut prendre plusieurs minutes selon le volume de données demandées",
    downloadCSVTitle: "Télécharger un exemple de fichier CSV",
    downloadCSVDescription: "Téléchargez un exemple de données CSV pour ce modèle et importez en masse les entrées dans vos pools de données. Veuillez sélectionner un fichier après avoir cliqué sur Soumettre",
    manageDataPoolProperties: "Gérer les propriétés du pool de données",
    manageDataPoolLabel: "Gérer le pool de données",
    addToListLabel: "Ajouter à la liste",
    selectListLabel: "Sélectionnez une liste pour ajouter des pools",
    schemaDialogTitle: "Aucun schéma supplémentaire n'a encore été créé",
    schemaDialogSecondaryTitle: "Commencez facilement à ajouter de nouveaux champs à votre pool de données ici",
    organizationMessage: "Continuez à conserver vos données sur notre réseau de confiance et choisissez l’un des forfaits suivants. Cependant, nous vous recommandons de contacter notre équipe pour collaborer et développer une solution spécifique pour vos besoins de gestion et d'analyse de données.",
    newOrganizationMessage: "Créons une organisation pour que vous puissiez finaliser votre inscription",
    deleteHandlingMessage: "Une fois supprimé du pool de données, ce contenu sera supprimé de chaque instance et ne pourra pas être récupéré. Souhaitez-vous toujours continuer ?",
    errorHandlingMessage: "Oops! Il semble que votre dernière opération se soit soldée par une erreur affichée ci-dessous. Nous vous conseillons de vous reconnecter s'il vous plaît. Cet incident a été signalé à notre équipe et la chasse aux bugs sera prochainement en cours",
    signUpLabel: "Inscrivez-vous ici",
    upgradeMessage: "Vous appréciez cette plateforme ? veuillez envisager de mettre à niveau votre compte ici",
    contentContainer: "gérez ici les modèles de données de contenu de votre organisation",
    relationsContainer: "gérez vos modèles de données de relations avec l'organisation ici",
    listsContainer: "gérer les listes dynamiques/statiques ici",
    reportsContainer: "gérer les rapports analytiques ici",
    settingsContainer: "gérer les paramètres des utilisateurs et de l'organisation ici",
    integrationsContainer: "gérer les intégrations avec des applications externes ici",
    addModelMessage: "ajoutez un nouveau modèle ici",
    addStatMessage: "ajouter une nouvelle statistique dynamique pour ce modèle de données",
    searchMessage: "soumettre à la recherche par nom",
    tableView: "afficher les pools de données sous forme de tableau",
    gridView: "afficher les pools de données au format grille",
    kanbanView: "afficher les pools de données au format Kanban",
    modelMessage: "gérer le modèle de données ici",
    filtersMessage: "gérer les filtres du modèle de données ici",
    schemasMessage: "gérer les champs du schéma du modèle de données ici",
    listsMessage: "ajouter du contenu à une liste existante ici",
    importMessage: "téléchargez un document pour importer en masse des pools de données ici",
    exportMessage: "recevoir un e-mail avec un lien pour télécharger un fichier d'exportation csv de ce modèle de données",
    refreshMessage: "actualiser le contenu à son état naturel ici",
    addPoolMessage: "ajouter du nouveau contenu ici",
    manageContentMessage: "gérer le contenu ici",
    checkContentMessage: "cliquez pour soumettre des mises à jour",
    openContentMessage: "cliquez pour naviguer dans ce contenu",
    sortMessage: "cliquez pour trier selon cette colonne",
    dragNDropMessage: "faites glisser et déposez la carte pour changer de statut",
    addListMessage: "ajoutez du contenu à cette liste ici",
    navigateBackToLists: "revenir aux listes",
    manageReportMessage: "gérer le rapport ici",
    addParentOrganization: "ajouter une organisation parente à votre organisation actuelle",
    addSubOrganization: "ajouter une nouvelle sous-organisation à votre organisation actuelle",
    currentOrganizationMessage: "organisation actuelle prête à explorer pour cette session",
    permissionMessage: "basculer pour limiter l'accès à cette fonctionnalité au sein de l'équipe",
    pdfDownloadMessage: "cliquez pour télécharger un fichier pdf de ce contenu",
    csvDownloadMessage: "cliquez pour télécharger un fichier csv de ce contenu",
    linkMessage: "cliquez pour visiter le lien",
    errorMessage: "Une erreur s'est produite!",
    createSuccessMessage: "créé avec succès!",
    updatedSuccessMessage: "Mis à jour avec succés!",
    removedSuccessMessage: "supprimé avec succès!",
    exportMutationMessage: "Demande d'exportation soumise avec succès!",
    importMutationMessage: "Demande d'importation soumise avec succès!",
    labelText: "étiquette",
    financialsLabel: "Données financières",
    financialsCollageLabel: "Gestion financière",
    financialsContainer: "gérez les modèles de données financières de votre organisation ici",
};
export default FrenchContent;
