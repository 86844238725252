import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Divider, Grid, FormTable, Loader, ErrorHandling, makeStyles } from "../components/mui";
import { FormBar } from "../components/common/forms";
import { ContentTypography } from "../components/common/content";
import AppLayout from "./common/AppLayout";
import ManageList from "../components/Lists/ManageList";
import { FormFieldOrder, UserPermission, FeatureType } from "../utils/enums";
import constants from "../utils/constants";
import ColorModeContext from "../routes/ColorModeContext";
import RouteConstants from "../routes/RouteConstants";
export const LISTS = gql `
  query OrganizationLists($organizationId: ID!, $filters: queryFilters!) {
    __type(name: "list") {
      name
      fields {
        name
        type {
          name
          kind
          enumValues {
            name
          }
        }
      }
    }
    lists(filters: $filters) {
      count
      page
      rowsPerPage
      order
      orderBy
      search
      headers {
        label
        key
        component
        scope
        align
        numeric
        width
      }
      list {
        createdAt
        id
        label
      }
    }
    organization(id: $organizationId) {
      id
      permissions {
        id
        feature
        permission
      }
    }
    currentUser {
      id
      role
      organization {
        id
        primaryTheme
      }
      platformSettings {
        mode
      }
      permissions {
        id
        feature
        permission
      }
    }
  }
`;
const useStyles = makeStyles((theme) => ({
    section: {
        padding: 16,
        [theme.breakpoints.down("md")]: {
            padding: 12,
        },
        [theme.breakpoints.down("sm")]: {
            padding: 8,
        },
    },
    listItem: {
        paddingRight: "16px !important",
    },
}));
const ListsContainer = (props) => {
    const userId = localStorage.getItem("userId") || null;
    const organizationId = localStorage.getItem("organizationId") || null;
    const classes = useStyles();
    const history = useHistory();
    const colorMode = React.useContext(ColorModeContext);
    const [actionDialogState, setActionDialogState] = React.useState({
        open: false,
        id: null,
        action: null,
    });
    React.useEffect(() => {
        if (!userId || !organizationId) {
            return history.push(RouteConstants.Login.path);
        }
    }, []);
    const { loading, error, data, refetch } = useQuery(LISTS, {
        variables: {
            organizationId,
            filters: {},
        },
    });
    React.useEffect(() => {
        if (data) {
            colorMode.defaultMode({
                mode: data?.currentUser?.platformSettings?.mode,
            });
            colorMode.togglePrimaryTheme({
                color: data?.currentUser?.organization?.primaryTheme || constants.DEFAULT_PRIMARY_THEME,
            });
        }
    }, [data]);
    if (loading)
        return _jsx(Loader, {});
    if (error)
        return _jsx(ErrorHandling, { error: error });
    const onListDelete = () => {
        setActionDialogState({
            open: false,
            id: null,
            action: null,
        });
    };
    const manageActionPermitted = data?.currentUser?.permissions?.find((permission) => permission?.feature === FeatureType.ListsManage)
        ?.permission === UserPermission.Manage;
    return (_jsxs(AppLayout, { children: [_jsx(ManageList, { organizationId: organizationId, actionDialogState: actionDialogState, setActionDialogState: setActionDialogState, onDelete: onListDelete }), _jsxs(Grid, { container: true, spacing: 2, alignItems: "flex-start", justifyContent: "center", className: classes.section, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(ContentTypography, { align: "right", variant: "h5", color: "primary", m: 1, fontWeight: "bold", field: "listsLabel" }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Divider, { flexItem: true }) }), _jsx(Grid, { item: true, xs: 12, className: classes.listItem, children: _jsx(FormBar, { showDelete: null, setActionDialogState: manageActionPermitted ? setActionDialogState : null, openFiltersDialog: false, setFiltersDialog: null, openSchemaDialog: false, setSchemaDialog: null, deleteFunctionality: false, view: null, setView: null, search: data?.lists?.search || "", onSearch: (value) => refetch?.({
                                filters: { search: value },
                            }), refetch: refetch }) }), _jsx(Grid, { item: true, xs: 12, className: classes.listItem, children: _jsx(Grid, { container: true, spacing: 2, children: data?.lists?.list?.length === 0 ? (_jsx(Grid, { item: true, xs: 12, children: _jsx(ContentTypography, { color: "secondar", m: 4, align: "center", field: "noDataDescription" }) })) : null }) }), data?.lists?.list?.length > 0 ? (_jsx(Grid, { item: true, xs: 12, className: classes.listItem, children: _jsx(FormTable, { headers: data?.lists?.headers || [], rows: data?.lists?.list || [], fields: [], notIncludedFields: ["createdAt", "label"], handleSelectAllVisible: false, handleRowVisible: manageActionPermitted, onSubmitCheck: () => null, count: data?.lists?.count || 0, page: data?.lists?.page || 0, order: data?.lists?.order || FormFieldOrder.asc, orderBy: data?.lists?.orderBy || constants.DEFAULT_SORT, rowsPerPage: data?.lists?.rowsPerPage || 0, rowsPerPageOptions: constants.ROWS_PER_PAGE.map((pages) => parseInt(pages)), handleRowAction: setActionDialogState, navigateRowVisible: true, navigateRowAction: (listId) => history.push(`/list/${listId}`), fields: data?.__type?.fields }) })) : null] })] }));
};
export default ListsContainer;
