import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
const IOSSwitch = styled((props) => (_jsx(Switch, { focusVisibleClassName: ".Mui-focusVisible", disableRipple: true, ...props })))(({ theme }) => ({
    width: 46,
    height: 30,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.primary[500],
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            color: "#33cf4d",
            border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 26,
        height: 26,
    },
    "& .MuiSwitch-track": {
        borderRadius: 30 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : theme.palette.grey[600],
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),
    },
}));
export default IOSSwitch;
