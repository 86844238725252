import { jsx as _jsx } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Grid, CircularProgress } from "@mui/material";
import ErrorHandling from "./ErrorHandling";
import constants from "../../utils/constants";
import { largeNumberLabel } from "../../utils/common";
import { currency, integrationType } from "../../utils/enums";
const CURRENCY_FIELD = gql `
  query CurrencyFieldQuery($type: String!) {
    integration(type: $type) {
      active
    }
  }
`;
const CURRENCY_CONVERSION = gql `
  mutation CurrencyFieldMutation($baseCurrency: String!, $poolCurrency: String!, $value: Int!) {
    convertCurrency(baseCurrency: $baseCurrency, poolCurrency: $poolCurrency, value: $value) {
      value
    }
  }
`;
const CurrencyField = ({ poolCurrency = currency.usd, baseCurrency = currency.usd, value = null }) => {
    const [getIntegration, { data: integrationData }] = useLazyQuery(CURRENCY_FIELD);
    const [convertValue, { loading, error, data }] = useMutation(CURRENCY_CONVERSION);
    React.useEffect(() => getIntegration({
        variables: {
            type: integrationType.fixer_io,
        },
        onCompleted: (data) => {
            if (poolCurrency !== baseCurrency && data?.integration?.active) {
                convertValue({
                    variables: {
                        poolCurrency,
                        baseCurrency,
                        value,
                    },
                });
            }
        },
    }), []);
    if (loading)
        return (_jsx(Grid, { container: true, alignItems: "center", justifyContent: "center", children: _jsx(Grid, { item: true, children: _jsx(CircularProgress, { size: 10, color: "primary" }) }) }));
    if (error)
        return _jsx(ErrorHandling, { error: error });
    if (poolCurrency === baseCurrency && !integrationData?.integration?.active) {
        return `${constants.CURRENCY_SYMBOLS?.[poolCurrency] || constants.CURRENCY_SYMBOLS.usd} ${largeNumberLabel(value)}`;
    }
    else {
        return `${constants.CURRENCY_SYMBOLS?.[poolCurrency] || constants.CURRENCY_SYMBOLS.usd} ${largeNumberLabel(value)} = ${constants.CURRENCY_SYMBOLS?.[baseCurrency] || constants.CURRENCY_SYMBOLS.usd} ${largeNumberLabel(data?.value)}`;
    }
};
export default CurrencyField;
