import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { FormSnackBar, Loader, ErrorHandling } from "../../components/mui";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { ManagePoolContentDialog } from "../common/dialogs";
import { FormContent } from "../common/forms";
import { FormFieldOrder } from "../../utils/enums";
import constants from "../../utils/constants";
import defaults from "../../utils/defaults";
import { getContent } from "../common/content";
const DEAL_FRAGMENT = gql `
  fragment DealFragment on deal {
    id
    createdAt
    updatedAt
    name
    description
    status
    dealType
    source
    amount
    closingDate
    expectedRevenue
    probability
    nextStep
    poolId
  }
`;
const DEALS = gql `
  query DealsView($filters: queryFilters!) {
    __type(name: "deal") {
      name
      fields {
        name
        type {
          name
          kind
          enumValues {
            name
          }
        }
      }
    }
    deals(filters: $filters) {
      count
      page
      rowsPerPage
      order
      orderBy
      search
      view
      headers {
        label
        key
        component
        scope
        align
        numeric
        width
      }
      list {
        ...DealFragment
      }
    }
  }
  ${DEAL_FRAGMENT}
`;
const DEAL = gql `
  query DealQuery($id: ID!) {
    deal(id: $id) {
      id
      createdAt
      updatedAt
      name
      description
      status
      dealType
      source
      amount
      closingDate
      expectedRevenue
      probability
      nextStep
      poolId
      accountOwner {
        id
      }
      client {
        id
      }
      lead {
        id
      }
      campaign {
        id
      }
      readOnly {
        label
        value
      }
      contents {
        id
        key
        value
        schemaId
        poolId
      }
    }
  }
`;
const ADD_DEAL = gql `
  mutation AddDeal($data: dealInput!) {
    addDeal(data: $data) {
      ...DealFragment
    }
  }
  ${DEAL_FRAGMENT}
`;
const EDIT_DEAL = gql `
  mutation EditDeal($id: ID!, $data: dealInput!) {
    editDeal(id: $id, data: $data) {
      ...DealFragment
    }
  }
  ${DEAL_FRAGMENT}
`;
const REMOVE_DEAL = gql `
  mutation RemoveDeal($id: ID!) {
    removeDeal(id: $id) {
      success
    }
  }
`;
const EXPORT_DEALS = gql `
  mutation ExportDeals($filters: queryFilters!) {
    exportDeals(filters: $filters) {
      id
    }
  }
`;
const IMPORT_DEALS = gql `
  mutation ImportDeals($data: usageInput!) {
    importDeals(data: $data) {
      id
    }
  }
`;
const Deals = ({ organizationId = null, organizationModelId = null, modelName = null, category = null, permissions = [], }) => {
    const notIncludedFields = [
        "id",
        "selected",
        "edited",
        "createdAt",
        "updatedAt",
        "poolId",
        "contents",
        "readOnly",
        "__typename",
    ];
    const [deal, setDeal] = React.useState(defaults.deal);
    const [actionDialogState, setActionDialogState] = React.useState({
        open: false,
        id: null,
        action: null,
    });
    const [snackBar, setSnackBar] = React.useState({
        open: false,
        message: "",
        type: "",
    });
    const { loading, error, data, refetch } = useQuery(DEALS, {
        variables: {
            filters: {},
        },
    });
    const [getDeal, { data: dealData }] = useLazyQuery(DEAL);
    const [addDeal] = useMutation(ADD_DEAL, {
        refetchQueries: ["DealsView"],
    });
    const [editDeal] = useMutation(EDIT_DEAL, {
        refetchQueries: ["DealsView"],
    });
    const [removeDeal] = useMutation(REMOVE_DEAL, {
        refetchQueries: ["DealsView"],
    });
    const [exportDeals] = useMutation(EXPORT_DEALS);
    const [importDeals] = useMutation(IMPORT_DEALS, {
        refetchQueries: ["DealsView"],
    });
    React.useEffect(() => {
        if (actionDialogState?.action === constants.OPERATION_TYPE.EDIT && actionDialogState?.id) {
            const id = actionDialogState?.id;
            getDeal({
                variables: { id },
            });
        }
    }, [actionDialogState]);
    React.useEffect(() => {
        if (dealData?.deal) {
            setDeal({
                ...dealData?.deal,
                accountOwner: dealData?.deal?.accountOwner?.id || "",
                client: dealData?.deal?.client?.id || "",
                campaign: dealData?.deal?.campaign?.id || "",
                lead: dealData?.deal?.lead?.id || "",
            });
        }
    }, [dealData]);
    if (loading)
        return _jsx(Loader, {});
    if (error)
        return _jsx(ErrorHandling, { error: error });
    const onContentAdd = (pool) => {
        const contents = pool?.contents?.map((content) => ({
            id: content.id,
            poolId: content.poolId,
            schemaId: content.schemaId,
            key: content.key,
            value: content.value,
        })) || [];
        let data = {
            ...pool,
            organizationId,
            contents,
            clientId: pool?.client,
            leadId: pool?.lead,
            accountOwnerId: pool?.accountOwner,
            campaignId: pool?.campaign,
            closingDate: new Date(pool?.closingDate),
            amount: parseInt(pool?.amount * 100),
            expectedRevenue: parseInt(pool?.expectedRevenue * 100),
            probability: parseFloat(pool?.probability),
        };
        delete data?.client;
        delete data?.accountOwner;
        delete data?.campaign;
        delete data?.lead;
        delete data?.readOnly;
        addDeal({
            variables: {
                data,
            },
            onCompleted: ({ addDeal }) => setSnackBar({
                open: true,
                message: `${addDeal?.name} ${getContent("createSuccessMessage")}`,
                type: "success",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
        setActionDialogState({
            open: false,
            id: null,
            action: null,
        });
    };
    const onContentEdit = (pool) => {
        const contents = pool?.contents?.map((content) => ({
            id: content.id,
            poolId: content.poolId,
            schemaId: content.schemaId,
            key: content.key,
            value: content.value,
        })) || [];
        let data = {
            ...pool,
            organizationId,
            contents,
            clientId: pool?.client,
            leadId: pool?.lead,
            accountOwnerId: pool?.accountOwner,
            campaignId: pool?.campaign,
            closingDate: new Date(pool?.closingDate),
            amount: parseInt(pool?.amount * 100),
            expectedRevenue: parseInt(pool?.expectedRevenue * 100),
            probability: parseFloat(pool?.probability),
        };
        delete data?.id;
        delete data?.createdAt;
        delete data?.updatedAt;
        delete data?.selected;
        delete data?.editable;
        delete data?.poolId;
        delete data?.readOnly;
        delete data?.client;
        delete data?.campaign;
        delete data?.accountOwner;
        delete data?.lead;
        delete data?.edited;
        delete data?.__typename;
        editDeal({
            variables: {
                id: pool.id,
                data,
            },
            onCompleted: ({ editDeal }) => setSnackBar({
                open: true,
                message: `${editDeal?.name} ${getContent("updatedSuccessMessage")}`,
                type: "success",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
        setActionDialogState({
            open: false,
            id: null,
            action: null,
        });
    };
    const onContentDelete = () => {
        removeDeal({
            variables: { id: deal?.id },
            onCompleted: () => setSnackBar({
                open: true,
                message: `${deal?.name} ${getContent("removedSuccessMessage")}`,
                type: "info",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
        setActionDialogState({
            open: false,
            id: null,
            action: null,
        });
    };
    const onDialogChange = ({ open = false, id = null, action = null }) => {
        setActionDialogState({
            open,
            id,
            action,
        });
    };
    const handleImport = ({ uploadAsset }) => {
        if (uploadAsset) {
            importDeals({
                variables: {
                    data: { uploadAsset },
                },
                onCompleted: () => setSnackBar({
                    open: true,
                    message: `${getContent("importMutationMessage")}`,
                    type: "info",
                }),
                onError: () => setSnackBar({
                    open: true,
                    message: `${getContent("errorMessage")}`,
                    type: "error",
                }),
            });
        }
    };
    const handleExport = () => {
        exportDeals({
            variables: {
                filters: {
                    order: data?.deals?.order,
                    orderBy: data?.deals?.orderBy,
                    search: data?.deals?.search,
                },
            },
            onCompleted: () => setSnackBar({
                open: true,
                message: `${getContent("exportMutationMessage")}`,
                type: "info",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormSnackBar, { snackBar: snackBar, setSnackBar: setSnackBar }), _jsx(ManagePoolContentDialog, { displayPool: true, poolData: deal || null, poolId: deal?.poolId || null, defaultSchema: defaults.deals, organizationModelId: organizationModelId, open: actionDialogState?.open || false, onDialogChange: onDialogChange, onSave: actionDialogState.action === constants.OPERATION_TYPE.ADD ? onContentAdd : onContentEdit, notIncludedFields: notIncludedFields, fields: data?.__type?.fields, operationType: actionDialogState?.action || constants.OPERATION_TYPE.ADD, onDelete: actionDialogState.action === constants.OPERATION_TYPE.EDIT ? onContentDelete : null, permissions: permissions }), _jsx(FormContent, { pool: false, organizationModelId: organizationModelId || null, modelName: modelName, category: category, headers: data?.deals?.headers || [], data: data?.deals?.list || [], count: data?.deals?.count || 0, page: data?.deals?.page || 0, defaultView: data?.deals?.view || null, order: data?.deals?.order || FormFieldOrder.asc, orderBy: data?.deals?.orderBy || constants.DEFAULT_SORT, search: data?.deals?.search || "", defaultOrder: constants.DEFAULT_ORDER, rowsPerPage: data?.deals?.rowsPerPage || 0, editMutation: onContentEdit, refetch: refetch, defaultSchema: defaults.deals, notIncludedFields: notIncludedFields, fields: data?.__type?.fields, actionDialogState: actionDialogState?.open || false, setActionDialogState: onDialogChange, handleExport: handleExport, handleImport: handleImport, loading: loading, error: error, permissions: permissions })] }));
};
export default Deals;
