import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Typography, Icon, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
    button: {
        padding: "4px 8px 4px 8px",
        color: "black",
        borderColor: "black",
        borderStyle: "solid",
        border: "6px",
        borderRadius: "24px !important",
        "&:hover": {
            color: theme.palette.primary[500],
        },
    },
}));
const Pill = ({ variant = "filter", leftIcon = null, rightIcon = null, isSelected = false, onClick = () => null, label = "", disabled = false, }) => {
    const theme = useTheme();
    const classes = useStyles();
    return (_jsx(Button, { variant: isSelected ? "contained" : "outlined", color: "primary", sx: {
            borderRadius: "48px",
            padding: "8px 24px",
            textTransform: "none",
        }, onClick: onClick, children: _jsxs("div", { style: { display: "flex", alignItems: "center" }, children: [leftIcon && (_jsx(Icon, { className: leftIcon, fontSize: 14, color: "inherit", style: { paddingRight: 8 }, "data-testid": "pill-left-icon" })), _jsx(Typography, { variant: "subtitle", color: "inherit", fontWeight: "bold", children: label }), rightIcon && (_jsx(Icon, { className: rightIcon, fontSize: 14, color: "inherit", style: { marginLeft: 8 }, "data-testid": "pill-right-icon" }))] }) }));
};
export default Pill;
