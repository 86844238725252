import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { gql, useQuery } from "@apollo/client";
import { Grid, CircularProgress, Paper, Typography, ErrorHandling, useMediaQuery, useTheme } from "../../mui";
import { makeStyles } from "@mui/styles";
import { UserPermission, FeatureType } from "../../../utils/enums";
import constants from "../../../utils/constants";
const STATISTIC = gql `
  query StatisticsDialog($id: ID!) {
    statistic(id: $id) {
      id
      label
      value
      operation
      fieldName
      modelName
      calculation
      calculationValue
      star
    }
  }
`;
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 16,
        minWidth: 128,
        height: 128,
        cursor: "pointer",
        width: "max-content",
    },
    paperItemGroup: {
        margin: 8,
    },
}));
const FormStatistic = ({ id = null, permissions = [], openManageStats = null, setManageStats = () => null }) => {
    const classes = useStyles();
    const theme = useTheme();
    const mdMatches = useMediaQuery(theme.breakpoints.down("md"));
    const { loading, error, data } = useQuery(STATISTIC, {
        variables: {
            id,
        },
    });
    if (loading)
        return (_jsx(Paper, { elevation: 6, className: classes.paper, children: _jsx(Grid, { container: true, alignItems: "center", justifyContent: "center", children: _jsx(Grid, { item: true, sx: { margin: "16%" }, children: _jsx(CircularProgress, { size: 40, color: "primary" }) }) }) }));
    if (error)
        return _jsx(ErrorHandling, { error: error });
    const manageActionPermitted = permissions?.find((permission) => permission?.feature === FeatureType.StatisticsManage)?.permission ===
        UserPermission.Manage;
    return (_jsx(Paper, { elevation: 6, className: classes.paper, onClick: () => manageActionPermitted
            ? setManageStats({
                open: true,
                stat: data?.statistic,
                action: constants.OPERATION_TYPE.EDIT,
            })
            : null, children: _jsxs("div", { className: classes.paperItemGroup, children: [_jsx(Typography, { variant: "subtitle2", fontWeight: "bold", color: "secondary", children: data?.statistic?.label || "–" }), _jsx(Typography, { variant: "h4", fontWeight: "bold", children: data?.statistic?.value || "–" })] }) }));
};
export default FormStatistic;
