import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { useMutation } from "@apollo/client";
import { FormSnackBar, Card, CardContent, CardActions, Button, makeStyles, Grid, FormControl, FormGroup, FormControlLabel, RadioGroup, Radio, Switch, FormHelperText, FormLabel, Divider, Loader, } from "../../mui";
import { ContentTypography, getContent } from "../../common/content";
import defaults from "../../../utils/defaults";
import { DeviceNotification } from "../../../utils/enums";
import { EDIT_USER } from "./Personal";
const useStyles = makeStyles((theme) => ({
    card: {
        overflow: "hidden",
        overflowX: "hidden",
        padding: 20,
        borderRadius: 9,
    },
    tabLabel: {
        fontWeight: "bold",
    },
    untransformBttn: {
        textTransform: "none",
    },
}));
const Notifications = ({ data }) => {
    const classes = useStyles();
    const [actionsVisible, setActionsVisible] = React.useState(false);
    const [notificationSettings, setNotificationSettings] = React.useState(defaults.users.notificationSettings);
    const [snackBar, setSnackBar] = React.useState({
        open: false,
        message: "",
        type: "",
    });
    const [editUser, { loading }] = useMutation(EDIT_USER);
    React.useEffect(() => {
        setNotificationSettings(data?.user?.notificationSettings || defaults.users.notificationSettings);
    }, [data]);
    if (loading)
        return _jsx(Loader, { size: 60 });
    return (_jsxs(_Fragment, { children: [_jsx(FormSnackBar, { snackBar: snackBar, setSnackBar: setSnackBar }), _jsxs(Card, { className: classes.card, children: [_jsx(CardContent, { children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsxs(Grid, { item: true, xs: 12, children: [_jsx(ContentTypography, { align: "left", variant: "h4", color: "primary", field: "notificationsLabel" }), _jsx(Divider, { flexItem: true })] }), _jsx(Grid, { item: true, xs: 12, children: _jsxs(FormControl, { component: "fieldset", variant: "standard", children: [_jsx(FormLabel, { component: "legend", children: _jsx(ContentTypography, { color: "primary", field: "emailNotificationsLabel" }) }), _jsx(FormHelperText, { children: getContent("emailNotificationsMessage") }), _jsxs(FormGroup, { children: [_jsx(FormControlLabel, { control: _jsx(Switch, { color: "primary", checked: notificationSettings?.dailyReports || false, onChange: (e) => {
                                                                setActionsVisible(true);
                                                                setNotificationSettings({
                                                                    ...notificationSettings,
                                                                    dailyReports: !notificationSettings.dailyReports,
                                                                });
                                                            }, name: "dailyReports" }), label: getContent("dailyReportsLabel") }), _jsx(FormHelperText, { children: getContent("dailyReportsMessage") }), _jsx(FormControlLabel, { control: _jsx(Switch, { color: "primary", checked: notificationSettings?.dealsClosing || false, onChange: (e) => {
                                                                setActionsVisible(true);
                                                                setNotificationSettings({
                                                                    ...notificationSettings,
                                                                    dealsClosing: !notificationSettings.dealsClosing,
                                                                });
                                                            }, name: "dealsClosing" }), label: getContent("dealsClosingAlert") }), _jsx(FormHelperText, { children: getContent("dealsClosingMessage") }), _jsx(FormControlLabel, { control: _jsx(Switch, { color: "primary", checked: notificationSettings?.deletionAlerts || false, onChange: (e) => {
                                                                setActionsVisible(true);
                                                                setNotificationSettings({
                                                                    ...notificationSettings,
                                                                    deletionAlerts: !notificationSettings.deletionAlerts,
                                                                });
                                                            }, name: "deletionAlerts" }), label: getContent("contentDeletionAlert") }), _jsx(FormHelperText, { children: getContent("contentDeletionMessage") })] })] }) }), _jsx(Grid, { item: true, xs: 12, children: _jsxs(FormControl, { component: "fieldset", variant: "standard", children: [_jsx(FormLabel, { component: "legend", children: _jsx(ContentTypography, { color: "primary", field: "deviceNotificationsLabel" }) }), _jsxs(RadioGroup, { name: "device", value: notificationSettings.device, onChange: (e) => {
                                                    setActionsVisible(true);
                                                    setNotificationSettings({
                                                        ...notificationSettings,
                                                        [e.target.name]: e.target.value,
                                                    });
                                                }, children: [_jsx(FormControlLabel, { value: DeviceNotification.doNOtNotify, control: _jsx(Radio, { color: "primary" }), label: DeviceNotification.doNOtNotify }), _jsx(FormControlLabel, { value: DeviceNotification.security, control: _jsx(Radio, { color: "primary" }), label: DeviceNotification.security }), _jsx(FormHelperText, { children: getContent("securityUpdatesMessage") }), _jsx(FormControlLabel, { value: DeviceNotification.all, control: _jsx(Radio, { color: "primary" }), label: DeviceNotification.all }), _jsx(FormHelperText, { children: getContent("allUpdatesMessage") })] })] }) })] }) }), actionsVisible ? (_jsx(CardActions, { children: _jsxs(Grid, { item: true, xs: 12, container: true, spacing: 2, justifyContent: "flex-end", alignItems: "flex-end", children: [_jsx(Grid, { item: true, children: _jsx(Button, { color: "primary", className: classes.untransformBttn, onClick: () => {
                                            const id = data?.user?.id;
                                            const { dailyReports, dealsClosing, deletionAlerts, device } = notificationSettings;
                                            editUser({
                                                variables: {
                                                    id,
                                                    data: { notificationSettings: { dailyReports, dealsClosing, deletionAlerts, device } },
                                                },
                                                onCompleted: () => setSnackBar({
                                                    open: true,
                                                    message: `Updated personal notifications successfully!`,
                                                    type: "success",
                                                }),
                                                onError: () => setSnackBar({
                                                    open: true,
                                                    message: `${getContent("errorMessage")}`,
                                                    type: "error",
                                                }),
                                            });
                                            setActionsVisible(false);
                                        }, children: _jsx(ContentTypography, { variant: "button", color: "primary", fontWeight: "bold", sx: { textTransform: "none" }, field: "saveChangesLabel" }) }) }), _jsx(Grid, { item: true, children: _jsx(Button, { className: classes.untransformBttn, onClick: () => {
                                            setNotificationSettings(data?.user?.notificationSettings
                                                ? data?.user?.notificationSettings
                                                : defaults.users.notificationSettings);
                                            setActionsVisible(false);
                                        }, children: _jsx(ContentTypography, { variant: "button", color: "secondary", fontWeight: "bold", sx: { textTransform: "none" }, field: "cancelLabel" }) }) })] }) })) : null] })] }));
};
export default Notifications;
