import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import React from "react";
import { FormSnackBar, Loader, ErrorHandling } from "../../components/mui";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { ManagePoolContentDialog } from "../common/dialogs";
import { FormContent } from "../common/forms";
import { FormFieldOrder } from "../../utils/enums";
import constants from "../../utils/constants";
import defaults from "../../utils/defaults";
import { getContent } from "../common/content";
const SUBSCRIPTION_FRAGMENT = gql `
  fragment SubscriptionFragment on subscription {
    id
    createdAt
    updatedAt
    name
    status
    description
    trialStart
    trialEnd
    startDate
    endedAt
    currentPeriodStart
    currentPeriodEnd
    backdateStartDate
    billingCycleAnchor
    cancelAt
    canceledAt
    daysUntilDue
    trialPeriodDays
    applicationFeePercent
    cancelAtPeriodEnd
    trialFromPlan
    offSession
    nextPendingInvoiceItemInvoice
    collectionMethod
    missingPaymentMethod
    poolId
  }
`;
const SUBSCRIPTIONS = gql `
  query SubscriptionsView($filters: queryFilters!) {
    __type(name: "subscription") {
      name
      fields {
        name
        type {
          name
          kind
          enumValues {
            name
          }
        }
      }
    }
    subscriptions(filters: $filters) {
      count
      page
      rowsPerPage
      order
      orderBy
      search
      view
      headers {
        label
        key
        component
        scope
        align
        numeric
        width
      }
      list {
        ...SubscriptionFragment
      }
    }
  }
  ${SUBSCRIPTION_FRAGMENT}
`;
const SUBSCRIPTION = gql `
  query SubscriptionQuery($id: ID!) {
    subscription(id: $id) {
      id
      ...SubscriptionFragment
      client {
        id
      }
      deal {
        id
      }
      accountOwner {
        id
      }
      readOnly {
        label
        value
      }
      contents {
        id
        key
        value
        schemaId
        poolId
      }
    }
  }
  ${SUBSCRIPTION_FRAGMENT}
`;
const ADD_SUBSCRIPTION = gql `
  mutation AddSubscription($data: subscriptionInput!) {
    addSubscription(data: $data) {
      ...SubscriptionFragment
    }
  }
  ${SUBSCRIPTION_FRAGMENT}
`;
const EDIT_SUBSCRIPTION = gql `
  mutation EditSubscription($id: ID!, $data: subscriptionInput!) {
    editSubscription(id: $id, data: $data) {
      ...SubscriptionFragment
    }
  }
  ${SUBSCRIPTION_FRAGMENT}
`;
const REMOVE_SUBSCRIPTION = gql `
  mutation RemoveSubscription($id: ID!) {
    removeSubscription(id: $id) {
      success
    }
  }
`;
const EXPORT_SUBSCRIPTIONS = gql `
  mutation ExportSubscriptions($filters: queryFilters!) {
    exportSubscriptions(filters: $filters) {
      id
    }
  }
`;
const IMPORT_SUBSCRIPTIONS = gql `
  mutation ImportSubscriptions($data: usageInput!) {
    importSubscriptions(data: $data) {
      id
    }
  }
`;
const Subscriptions = ({ organizationId = null, organizationModelId = null, modelName = null, category = null, permissions = [], }) => {
    const notIncludedFields = [
        "id",
        "selected",
        "edited",
        "createdAt",
        "updatedAt",
        "poolId",
        "contents",
        "readOnly",
        "pauseCollection",
        "cancellationDetails",
        "__typename",
    ];
    const [subscription, setSubscription] = React.useState(defaults.subscriptions);
    const [actionDialogState, setActionDialogState] = React.useState({
        open: false,
        id: null,
        action: null,
    });
    const [snackBar, setSnackBar] = React.useState({
        open: false,
        message: "",
        type: "",
    });
    const { loading, error, data, refetch } = useQuery(SUBSCRIPTIONS, {
        variables: {
            filters: {},
        },
    });
    const [getSubscription, { data: subscriptionData }] = useLazyQuery(SUBSCRIPTION);
    const [addSubscription] = useMutation(ADD_SUBSCRIPTION, {
        refetchQueries: ["SubscriptionsView"],
    });
    const [editSubscription] = useMutation(EDIT_SUBSCRIPTION, {
        refetchQueries: ["SubscriptionsView"],
    });
    const [removeSubscription] = useMutation(REMOVE_SUBSCRIPTION, {
        refetchQueries: ["SubscriptionsView"],
    });
    const [exportSubscription] = useMutation(EXPORT_SUBSCRIPTIONS);
    const [importSubscriptions] = useMutation(IMPORT_SUBSCRIPTIONS, {
        refetchQueries: ["SubscriptionsView"],
    });
    React.useEffect(() => {
        if (actionDialogState?.action === constants.OPERATION_TYPE.EDIT && actionDialogState?.id) {
            const id = actionDialogState?.id;
            getSubscription({
                variables: { id },
            });
        }
    }, [actionDialogState]);
    React.useEffect(() => {
        if (subscriptionData?.subscription) {
            setSubscription({
                ...subscriptionData?.subscription,
                client: subscriptionData?.subscription?.client?.id || "",
                deal: subscriptionData?.subscription?.deal?.id || "",
                accountOwner: subscriptionData?.subscription?.accountOwner?.id || "",
            });
        }
    }, [subscriptionData]);
    if (loading)
        return _jsx(Loader, {});
    if (error)
        return _jsx(ErrorHandling, { error: error });
    const onContentAdd = (pool) => {
        const contents = pool?.contents?.map((content) => ({
            id: content.id,
            poolId: content.poolId,
            schemaId: content.schemaId,
            key: content.key,
            value: content.value,
        })) || [];
        let data = {
            ...pool,
            organizationId,
            contents,
            accountOwnerId: pool?.accountOwner,
            clientId: pool?.client,
            dealId: pool?.deal,
            daysUntilDue: parseInt(pool?.daysUntilDue),
        };
        delete data?.readOnly;
        delete data?.accountOwner;
        delete data?.client;
        delete data?.deal;
        addSubscription({
            variables: {
                data,
            },
            onCompleted: ({ addSubscription }) => setSnackBar({
                open: true,
                message: `${addSubscription?.name} ${getContent("createSuccessMessage")}`,
                type: "success",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
        setActionDialogState({
            open: false,
            id: null,
            action: null,
        });
    };
    const onContentEdit = (pool) => {
        const contents = pool?.contents?.map((content) => ({
            id: content.id,
            poolId: content.poolId,
            schemaId: content.schemaId,
            key: content.key,
            value: content.value,
        })) || [];
        let data = {
            ...pool,
            organizationId,
            contents,
            accountOwnerId: pool?.accountOwner,
            clientId: pool?.client,
            dealId: pool?.deal,
            daysUntilDue: parseInt(pool?.daysUntilDue),
        };
        delete data?.id;
        delete data?.createdAt;
        delete data?.updatedAt;
        delete data?.selected;
        delete data?.editable;
        delete data?.accountOwner;
        delete data?.client;
        delete data?.deal;
        delete data?.poolId;
        delete data?.readOnly;
        delete data?.edited;
        delete data?.__typename;
        editSubscription({
            variables: {
                id: pool.id,
                data,
            },
            onCompleted: ({ editSubscription }) => setSnackBar({
                open: true,
                message: `${editSubscription?.name} ${getContent("updatedSuccessMessage")}`,
                type: "success",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
        setActionDialogState({
            open: false,
            id: null,
            action: null,
        });
    };
    const onContentDelete = () => {
        removeSubscription({
            variables: { id: subscription?.id },
            onCompleted: () => setSnackBar({
                open: true,
                message: `${subscription?.name} ${getContent("removedSuccessMessage")}`,
                type: "info",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
        setActionDialogState({
            open: false,
            id: null,
            action: null,
        });
    };
    const onDialogChange = ({ open = false, id = null, action = null }) => {
        setActionDialogState({
            open,
            id,
            action,
        });
    };
    const handleImport = ({ uploadAsset }) => {
        if (uploadAsset) {
            importSubscriptions({
                variables: {
                    data: { uploadAsset },
                },
                onCompleted: () => setSnackBar({
                    open: true,
                    message: `${getContent("importMutationMessage")}`,
                    type: "info",
                }),
                onError: () => setSnackBar({
                    open: true,
                    message: `${getContent("errorMessage")}`,
                    type: "error",
                }),
            });
        }
    };
    const handleExport = () => {
        exportSubscription({
            variables: {
                filters: {
                    order: data?.subscriptions?.order,
                    orderBy: data?.subscriptions?.orderBy,
                    search: data?.subscriptions?.search,
                },
            },
            onCompleted: () => setSnackBar({
                open: true,
                message: `${getContent("exportMutationMessage")}`,
                type: "info",
            }),
            onError: () => setSnackBar({
                open: true,
                message: `${getContent("errorMessage")}`,
                type: "error",
            }),
        });
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormSnackBar, { snackBar: snackBar, setSnackBar: setSnackBar }), _jsx(ManagePoolContentDialog, { displayPool: true, poolData: subscription || null, poolId: subscription?.poolId || null, defaultSchema: defaults.subscriptions, organizationModelId: organizationModelId, open: actionDialogState?.open || false, onDialogChange: onDialogChange, onSave: actionDialogState.action === constants.OPERATION_TYPE.ADD ? onContentAdd : onContentEdit, notIncludedFields: notIncludedFields, fields: data?.__type?.fields, operationType: actionDialogState?.action || constants.OPERATION_TYPE.ADD, onDelete: actionDialogState.action === constants.OPERATION_TYPE.EDIT ? onContentDelete : null, permissions: permissions }), _jsx(FormContent, { pool: false, organizationModelId: organizationModelId || null, modelName: modelName, category: category, headers: data?.subscriptions?.headers || [], data: data?.subscriptions?.list || [], count: data?.subscriptions?.count || 0, page: data?.subscriptions?.page || 0, defaultView: data?.subscriptions?.view || null, order: data?.subscriptions?.order || FormFieldOrder.asc, orderBy: data?.subscriptions?.orderBy || constants.DEFAULT_SORT, search: data?.subscriptions?.search || "", defaultOrder: constants.DEFAULT_ORDER, rowsPerPage: data?.subscriptions?.rowsPerPage || 0, editMutation: onContentEdit, refetch: refetch, defaultSchema: defaults.subscriptions, notIncludedFields: notIncludedFields, fields: data?.__type?.fields, actionDialogState: actionDialogState?.open || false, setActionDialogState: onDialogChange, handleExport: handleExport, handleImport: handleImport, loading: loading, error: error, permissions: permissions })] }));
};
export default Subscriptions;
